<template>

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Meru County Documents Header</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet">

    </head>

    <body @contextmenu.prevent style="padding: 0px; margin: 0px; font-family: 'Montserrat', sans-serif; background-color: #a2a2a21c;box-sizing: border-box;">

    <button class="print-btn" onclick="window.print()"><img :src="'./assets/printer.svg'" alt="Printer Icon"></button>
    <button class="print-btn email-doc" title="send On Email"><img src="./assets/email-icon.png" alt="Printer Icon "></button>


    <page class="page" style="width: 210mm; height:297mm; display: flex; position: relative; flex-flow: column; background-color: white;">
        <!-- main content -->
        <div style="padding: 8.5mm;">
            <header style="display: flex; height: 47mm; align-items: center; position: relative; width: 100%; justify-content: space-between;">
                <div>
                    <img :src="'./logos/arms.png'" style="height: 36mm; margin-right: 0mm;">
                </div>
                <div style="text-align: center; margin-right: 8.5mm; margin-left: 8.5mm;">
                    <h2 style="
                        margin-top: 0px;
                        font-size: 26px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 1.2mm;
                        padding-bottom: 0px;

                    ">
                        County Government <br> Of {{county.countyName}}
                    </h2>

                    <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 18px;
                    font-weight: bold; text-transform: uppercase; margin-bottom: 4.5mm;">
                        ({{county.center}})
                    </h4>

                    <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 14px;
                    font-weight: 400;">
                        {{county.address}}<br>
                        Email: {{county.email}}
                    </h4>

                    <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 13px;
                    font-weight: 400; font-style: italic; font-family: 'Times New Roman', Times, serif; margin-top: 2mm;">
                        <span style="font-style: italic; font-family: 'Times New Roman', Times, serif; margin-right: 3mm;">{{county.tagline}}</span>
                        <span style="font-style: italic; font-family: 'Times New Roman', Times, serif;">{{county.tagline2}}</span>
                    </h4>

                </div>
                <div>
                    <img :src="county_logo()" style="height: 36mm; margin-right: 0mm;">
                </div>

            </header>
            <div>
                <img src="./assets/green-line.png" style="width: 100%;">
            </div>

            <!-- contact information and QR code -->
            <div style="display: flex; justify-content: center;">

                <!-- contact information -->
                <div style="font-size: 12px;">
                    <h2 style="
                        margin-top: 0px;
                        font-size: 18px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 3.3mm;
                        margin-top:3.3mm;
                        padding-bottom: 0px;
                        text-align: center;

                    ">
                        Summary Statement for collected revenue by Sub County
                    </h2>

                    <p style="padding: 0px; margin: 0px; padding-bottom: 1.5mm; text-align: center; text-transform: capitalize; font-family: 'Times New Roman', Times, serif; font-style: italic; font-weight: bold;">{{dateRange}} {{ new Date().toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true}) }} </p>




                </div>


            </div>

            <!-- the table -->

            <!-- the table contents -->
            <table style="margin-top:9.3mm; margin-bottom: 9.3mm; border-spacing: initial; min-height: 70mm; text-transform: capitalize; width: 100%;">
                <thead class="" style="text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                background: #eaf6e9;
                padding: 2.5mm;">
                <tr>
                    <td style="padding: 2.5mm; width: 10mm;">#</td>
                    <td style="padding: 2.5mm;">Sub County</td>
                    <td style="text-align: right; padding: 2.5mm; width: 50mm;">Collected Revenue</td>
                </tr>

                </thead>
                <tbody style="font-size: 12px;">
                <tr  v-for="(item, index) in subCountiesRevenue" :value="item" :key="index">
                    <td style="padding: 2.5mm;">{{index+1}}.</td>
                    <td style="padding: 2.5mm;">{{item.subCountyName}}</td>
                    <td style="text-align: right; padding: 2.5mm;">
                        <strong>KES {{numberFormat(item.amountTotal)}}</strong>
                    </td>
                </tr>


                </tbody>
                <thead class="" style="text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                background: #eaf6e9;
                padding: 2.5mm;">
                <tr>
                    <th  style="padding: 2.5mm; text-align: left;" colspan="2" >Report Total</th>

                    <th style="text-align: right; padding: 2.5mm; width: 50mm;">KES {{numberFormat(totalAmount)}}</th>
                </tr>

                </thead>
            </table>

            <div>
                <img src="./assets/green-line.png" style="width: 100%; margin-bottom: 4mm;">
            </div>

        </div>

        <!-- the footer starts here -->
        <div style="padding: 8.5mm;width: 100%;background-color: #eaf6e9;margin-top: 9mm;
        display: flex;flex-flow: column;align-items: baseline;justify-content: space-between;
        position: absolute;
        height: 70mm;
        bottom: 0mm;">
            <div style="display: flex;justify-content: space-between;width: 100%;">

                <div style="font-size: 12px;padding-top:3.3mm;">
                    <h2 style="
                        margin-top: 0px;
                        font-size: 18px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 3.3mm;
                        padding-bottom: 0px;
                        text-transform: uppercase;
                        display: none;

                    ">
                        Our Digital Platforms
                    </h2>

                    <p style="padding: 0px; margin: 0px; padding-bottom: 1.5mm;">Prepared By <strong>{{names}}</strong></p>
                    <p style="padding: 0px; margin: 0px; padding-bottom: 1.5mm;">Prepared On  <strong>{{ new Date().toLocaleString('en-US', {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true}) }}</strong></p>
                    <p style="padding: 0px; margin: 0px; padding-bottom: 1.5mm;">Source  <strong>County Biller Dashboard</strong></p>
                </div>

                <div style="padding-top:3.3mm;padding-left: 17.3mm;">
                    <div style="display: flex; margin-bottom: 4mm; justify-content: center; align-items: center;">
                        <img :src="county_logo" style="
                        margin-right: 3.3mm;
                        height: 31mm;
                        opacity: 25%;
                    ">
                    </div>


                </div>

            </div>

            <footer style="position: relative; width: 100%;">
                <div style="position: absolute; bottom: 0mm;">

                    <div>

                        <p style="padding-bottom: 0px;margin-bottom:0px;font-size: 12px;margin-top: 0mm; font-family: 'Times New Roman', Times, serif; font-style: italic; font-weight: bold; text-align: center;">*** The amounts shown on this report do not include amounts uploaded, approved or voided after this report date. ***</p>

                        <img src="assets/green-line.png" style="width: 100%;">
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 2mm;">
                        <span style="font-family: 'Yellowtail', cursive;  color: #4ca82c; line-height: 0.9; font-size: 20px;">Thank You!</span>
                        <div style="display: flex; display: none; align-items: center; justify-content: center; font-size: 12px; margin-left: 8.5mm; text-transform: uppercase;">
                            <p style="text-transform: capitalize; font-family: 'Times New Roman', Times, serif; font-style:italic;">Powered By </p>

                            <div>
                                <img style="
                                height: 11mm;
                                margin-left: 3mm;" :src="county.poweredByLogo" alt="">
                            </div>


                        </div>
                    </div>
                </div>
            </footer>



        </div>
    </page>


    </body>

</template>

<script>
    /* eslint-disable */
    import {biller, county_logo, execute} from "@/api";

    export default {
        name: "SubCountyReport",
        data() {
            return {
                county: {
                    id: null,
                    countyID: null,
                    countyName: null,
                    tagline: null,
                    smsusername: null,
                    smspassword:null,
                    contact: null,
                    center: null,
                    address: null,
                    email: null,
                    headline: null,
                    tagline2: null,
                    ussd: null,
                    logo: null,
                    bank: null,
                    powered: null,
                    stateLogo: null
                },
                dateRange:'For the period 1st Mar 2023 To 30th Mar 2023',
                dateTo:'',
                dateFrom:'',
                names:'',
                totalAmount:null,
                subCountiesRevenue: [
                    {
                        subCountyID: "",
                        subCountyName: "",
                        amountToday: "",
                        amountTotal: ""
                    }
                ],
            };
        },
        mounted() {

            this.names = sessionStorage.getItem("names")
            this.dateTo = sessionStorage.getItem("dateTo")
            this.dateFrom = sessionStorage.getItem("dateFrom")

            if(this.dateFrom === ''){
                this.dateRange ='For the period this Financial Year'
            }else {
                const from = this.formatDate(sessionStorage.getItem("dateFrom"))
                const to = this.formatDate(sessionStorage.getItem("dateTo"))
                this.dateRange =`For the period ${from} To ${to}`
            }

            this.getSubCountiesRevenue()
          this.menuAccess()
        },
        methods: {
          county_logo() {
            return county_logo
          },

          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "SubCounty Report");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then(() => {
                  // alert(res.data.message);
                })
                .catch(() => {
                  // alert(e.message);
                });

          },

            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', { month: 'short' });
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            showDate() {
                const dateObj = new Date();
                const currentDate =
                    dateObj.getDate() +
                    "/" +
                    dateObj.getMonth() +
                    "/" +
                    dateObj.getFullYear();
                return currentDate;
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            getSubCountiesRevenue() {
                const data = new FormData();
                data.append("function", "getSubCountiesRevenue");
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.subCountiesRevenue = res.data.data.subCountiesRevenue;
                            this.totalAmount = res.data.data.totalAmount.totalAmount;
                            this.county = res.data.data.county;

                            //compute totals sub county
                            this.totalAmount = this.subCountiesRevenue.reduce((acc, cur) => {
                                let amountTotal = Number(cur.amountTotal);
                                console.log("####\n")
                                if (!isNaN(amountTotal)) {
                                    return acc + amountTotal;
                                } else {
                                    return acc;
                                }
                            }, 0);


                        } else {
                            alert(res.data.data.message);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            }
        },
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        -webkit-print-color-adjust: exact !important;
        /* Chrome, Safari */
        color-adjust: exact !important;
        /*Firefox*/
    }

    .print-btn {
        display: flex;
        z-index: 1000000000;
        position: fixed;
        background: #4aaa23;
        color: white;
        top: 30px;
        right: 30px;
        border-radius: 50%;
        padding: 1rem;
        margin: 0px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 0px 20px 4px rgb(136 136 136);
        cursor: pointer;
        transition: 0.4s;
    }
    .email-doc{
        top: 130px;
        background: #0063c0;
    }
    .print-btn img {
        height: 35px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
    }

    tbody tr:nth-child(even) {
        background-color: #0000000a;
    }

    tbody tr:nth-child(odd) {
        background-color: white;
        color: black;
    }

    @media print {
        .print-btn {
            display: none;
        }
        body {
            margin: 0px !important;
            padding: 0px !important;
            background-color: white;
        }
        @page {
            /* size: auto; */
            /* auto is the initial value */
            /* this affects the margin in the printer settings */
            /* width: 210mm;*/
            /* height: 297mm; */
            size: A4 portrait;
            margin: 0px;
            margin-bottom: 0px;
            margin: 0px;
        }
    }

    @page {
        /* size: auto; */
        /* auto is the initial value */
        /* this affects the margin in the printer settings */
        /* width: 210mm;
            height: 297mm; */
        size: A4 portrait;
        margin: 0px;
        margin-bottom: 0px;
        margin: 0px;
    }
</style>

