<template>

  <head>

    <meta charset="utf-8"/>
    <title>Desk Birdy | Business Permits</title>
    <meta content="width=device-width, initial-scale=1.0" name="viewport">
    <meta content="Rental management system by Nouveta LTD" name="description"/>
    <meta content="Kelvin Njuguna" name="author"/>
    <!-- App favicon -->
    <link href="assets/images/favicon.svg" rel="shortcut icon">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css"/>

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css"/>
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
          type="text/css"/>

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css"/>
    <!-- Bootstrap select styling -->
    <link href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css"
          rel="stylesheet">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link href="./assets/libs/owl.carousel/assets/owl.carousel.min.css" rel="stylesheet">

    <link href="./assets/libs/owl.carousel/assets/owl.theme.default.min.css" rel="stylesheet">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css"/>
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css"/>

  </head>

  <body @contextmenu.prevent data-sidebar="dark">


  <!-- Begin page -->
  <div id="layout-wrapper">

    <NavigationBar/>

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div id="content" class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Business Permits</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="#">Certificates & Licenses</a></li>
                    <li class="breadcrumb-item"><a href="cert-biz-stats.html">Business Permits</a>
                    </li>
                    <li class="breadcrumb-item active"> Applications Pending Validation</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->

          <ToolBar/>

          <!-- end of toolbar -->
          <div class="row">
            <div class="col-12">
              <div class="card">

                <div class="card-header bg-primar text-white border-bottom d-flex justify-content-between align-items-center py-3">
                  <h4 class="card-title text-whit text-uppercase mb-0">Previous register for {{subCountyFilter.subCountyName}}</h4>
                  <div>
                    <div class="flex-start d-flex align-items-center">



                      <!-- Sub County Dropdown -->
                      <div class="input-group input-group-sm">
                        <label class="input-group-text">Sub County</label>
                        <select class="form-select" @change="getWardsFilter(subCountyFilter.subCountyID)" v-model="subCountyFilter">
                          <option value="{}">Select All Sub Counties</option>
                          <option :key="index" :value="item"
                                  v-for="(item, index) in subCountiesFilter">
                            {{ item.subCountyName }}
                          </option>
                        </select>
                      </div>

                      <!-- Ward Dropdown -->
                      <div class="input-group mx-4 input-group-sm">
                        <label class="input-group-text">Ward</label>
                        <select class="form-select"  v-model="wardFilter">
                          <option value="{}">Select All Wards</option>
                          <option :key="index" :value="item"
                                  v-for="(item, index) in wardsFilter">
                            {{ item.wardName }}
                          </option>
                          <!-- Add more wards as needed -->
                        </select>
                      </div>

                      <!-- Zone/Market Dropdown -->
                      <div class="input-group input-group-sm me-3">
                        <label class="input-group-text">Zone/Market</label>
                        <select class="form-select"  @change="selectedZoneFilter($event)">
                          <option value="{}">Select All Zones</option>
                          <option :key="index" :value="JSON.stringify(item)"
                                  v-for="(item, index) in zonesFilter">
                            {{ item.zone }}
                          </option>
                          <!-- Add more markets as needed -->
                        </select>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="card-header bg-white border-bottom d-flex justify-content-between align-items-center py-3">
                  <p class="p-0 m-0 fw-bold text-capitalize">Please review each record carefully to ensure accuracy before approving it for invoicing or archiving. Only verified records will be processed, and invoices will be generated automatically for approved entries.</p>
                </div>


                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom d-flex">
                  <div class="d-flex align-items-center justify-content-center p-3 flex-grow-1"  role="toolbar">
                    <form class="app-search p-0 m-0 w-100">
                      <div class="position-relative">
                        <input v-model="search" type="text" class="form-control w-100" placeholder="Search... eg Business Name, ID Number, Bill Number, etc" spellcheck="false" data-ms-editor="true">
                        <span class="bx bx-search-alt"></span>
                      </div>
                    </form>
                  </div>
                  <div class="pr-3">
                    <button  v-if="getRight('DOWNLOAD REPORTS')" @click="downloadReportPDF()" class="btn btn-primary">Download </button>
                  </div>
                </div>

                <div class="card-body">
                  <div class="table-responsive ">

                    <table class="table table-striped table-striped-info table-s align-middle text-nowrap">
                      <thead class="bg-info text-black text-uppercase border border-info">
                      <tr>
                        <th title="Date and Time of Lab Test Order">Biz ID.</th>
                        <th title="Patient Visit Number">Status</th>
                        <th title="Invoice number for the scheduled test">Business</th>
                        <th title="Patient Name">Owner</th>
                        <th title="Outpatient or Inpatient">Registered On</th>
                        <th title="Doctor Who Ordered the Test">Sub Category</th>
                        <th title="Lab Test(s) Ordered">2024 Invoice</th>
                        <th>Changes</th>
                        <th class="text-right" title="Mode of Payment (e.g., Cash, Insurance)">Amount Invoiced</th>
                        <th class="text-right" title="Payment Status (Paid or Unpaid)">Amount Paid</th>
                        <th title="Available Actions" class="text-right">Actions</th>
                      </tr>
                      </thead>
                      <tbody class="border border-info">
                      <tr :key="index" :value="item" v-for="(item, index) in entries">
                        <td>{{item.businessID}}</td>
                        <td>
                          <!-- Record 2 - Invoiced -->
                          <span v-if="item.renewalStatus === 'INVOICED'" key="t-completed" class="badge rounded-pill bg-success text-black text-uppercase">{{ item.renewalStatus }}</span>
                          <!-- Record 3 - Archived (Red) -->
                          <span v-if="item.renewalStatus === 'ARCHIVED'" key="t-archived" class="badge rounded-pill bg-danger text-white text-uppercase">Archived</span>
                          <!-- Record 4 - Pending -->
                          <span
                              v-if="item.renewalStatus === 'PENDING'" key="t-new" class="badge rounded-pill bg-warning text-black text-uppercase">Pending</span>
                        </td>

                        <td>{{item.businessName}}<br>
                          <small class="text-info">{{item.subCountyName}} | {{item.wardName}} | {{item.zone}}</small>
                        </td>
                        <td>{{item.owner}} ({{item.ownerPhone}})</td>
                        <td>{{formattedDate(item.registeredOn)}} By {{item.registeredBy}}</td>
                        <td>{{item.feeDescription}}</td>
                        <td>{{item.billNo}}</td>
                        <td>{{item.changesCount}}</td>
                        <td class="text-right">{{formatPrice(item.billTotal)}}</td>
                        <td class="text-uppercase text-right">{{formatPrice(item.receiptAmount)}}</td>
                        <td class="text-uppercase text-right">
                          <div  class="d-flex justify-content-end">
                            <button @click="getBusinessDetails(item.id)"  v-if="item.renewalStatus !== 'PENDING'"  class="btn btn-sm btn-dark text-uppercase d-flex align-items-center ms-2" data-bs-toggle="modal" data-bs-target=".details-modal" title="Initiate Payment for Lab Tests">
                              <div>Details</div>
                              <div class="spinner-border text-white ms-2 d-none" style="height: 19px; width: 19px;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </button>

                            <button @click="getBusinessDetails(item.id,index)" v-else class="btn btn-sm btn-success text-uppercase d-flex align-items-center ms-2" data-bs-toggle="modal" data-bs-target=".receipting-modal" title="Initiate Payment for Lab Tests">
                              <div>Initiate Verification</div>
                              <div  v-if="loadingIndex === index" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </td>
                      </tr>

                      </tbody>

                      <tfoot class="border border-dark">
                      <tr class="table-primary text-black border-dark">
                        <th colspan="3">{{statusCounts}}</th>
                        <th colspan="6"></th>
                        <th class="text-right">KES {{numberFormat(totalPaidAmount)}}</th>
                        <th></th>
                      </tr>
                      </tfoot>
                    </table>

                    <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                         v-if="loading">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait
                          and have fun. Thank you for your interest.</p>

                      </div>
                    </div>
                    <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                         v-if="businesses.length<1 & loading === false">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" alt="No search results"
                             class="img mb-4"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter
                        criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>
                </div>
                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation example "
                       class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                      <select class="form-select w-auto font-13px " v-model="pageSize">
                        <option value="5">5 Rows</option>
                        <option value="10">10 Rows</option>
                        <option value="25">25 Rows</option>
                        <option value="50">50 Rows</option>
                        <option value="100">100 Rows</option>
                        <option value="150">150 Rows</option>
                        <option value="200">200 Rows</option>
                        <option value="5000">5000 Rows</option>
                        <option value="10000">5000 Rows</option>
                        <option value="1000000000000000">All Rows</option>
                      </select>
                    </div>

                    <ul class="pagination">
                      <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                      <li :class="{ disabled: currentPage === 1 }" class="page-item">
                        <a href="javascript: void(0);" :disabled="currentPage === 1" @click="prevPage" class="page-link"
                           tabindex="-1">Previous</a>
                      </li>
                      <!-- replace the v-for directive with the pagination function call -->
                      <li href="javascript: void(0);" :class="{ active: currentPage === page }" :key="page" class="page-item"
                          v-for="page in pagination(currentPage, totalPages, 3)">
                        <!-- check if the page is an ellipsis and display it as plain text -->
                        <span class="page-link" v-if="page === '...'">{{ page }}</span>
                        <!-- otherwise, display it as a clickable link -->
                        <a href="javascript: void(0);" @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                      </li>
                      <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                      <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                        <a href="javascript: void(0);" :disabled="currentPage === totalPages" @click="nextPage"
                           class="page-link">Next</a>
                      </li>
                    </ul>


                  </nav>
                </div>

              </div>
            </div>
            <!-- end col -->
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade receipting-modal"
           role="dialog"
           tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">{{businessDetails.businessName}} Business Details</h5>
              <span
                  class="badge rounded-pill bg-warning text-uppercase font-12px text-uppercase mx-3 text-dark">Pending Invoicing For {{Number(entry.year)+1}}</span>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details </p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="javascript: void(0);">(By {{businessDetails.createdBy}})</a></th>
                          </thead>
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Name</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.businessName" type="text" class="form-control form-control-sm" placeholder="Enter Business Name">
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Sub County</p>
                            </td>
                            <td class="">
                              <select class="form-select form-select-sm"   id="country" fdprocessedid="fw9x5" @change="getWards(subCounty.subCountyID)"  v-model="subCounty">
                                <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                              </select>

                            </td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Ward </p>
                            </td>
                            <td class="">
                              <select class="form-select form-select-sm" fdprocessedid="fw9x5" v-model="ward" >
                                <option :key="`${index}`" :value="item" v-for="(item, index) in wards"><a
                                    class="dropdown-item" href="#">{{item.wardName}}</a></option>
                              </select>

                            </td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Zone</p>
                            </td>
                            <td class="">

                              <select class="form-select form-select-sm" fdprocessedid="fw9x5"  v-model="zone">
                                <option v-for="(item, index) in zones" :key="`${index}`"><a class="dropdown-item" href="#">{{item.zone}}</a></option>
                              </select>


                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Plot No.</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.plotNumber"  type="text" class="form-control form-control-sm" placeholder="Plot Number">
                            </td>


                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                No.
                              </p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.kraPin" type="text" class="form-control form-control-sm" placeholder="Enter VAT NO.">
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Physical Address
                              </p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.physicalAddress" type="text" class="form-control form-control-sm" placeholder="Enter The ADdress" value="Next to Kianjokoma Market">
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Building Name
                              </p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.buildingName" type="text" class="form-control form-control-sm" placeholder="Enter The Building Name" value="The Tower">
                            </td>
                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Floor Number</p>
                            </td>
                            <td class="">
                              <select class="form-select form-select-sm" fdprocessedid="fw9x5" v-model="floor">
                                <option v-for="(item, index) in floors" :key="`${index}`"><a class="dropdown-item" href="#">{{item.floor}}</a></option>
                              </select>


                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Stall/Room No.
                              </p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.roomNo" type="text" class="form-control form-control-sm" placeholder="Enter No. if Applicable" value="NA">
                            </td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-warning">
                          <th colspan="3" class="text-uppercase ">
                            <div class="justify-content-between d-flex">
                              <div>
                                {{entry.year}} Invoicing Details
                              </div>
                              <div>
                                <a @click="printBill(entry.billNo)" href="javascript: void(0);" target="new" class="btn btn-primary btn-sm text-uppercase">Open</a>
                              </div>
                            </div>
                          </th>
                          </thead>
                          <tbody>
                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Invoice No.</p>

                            </td>

                            <td class=""><span class="text-primary">{{billDetail.billNo}}</span></td>

                          </tr>
                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Total Invoiced Amount</p>

                            </td>
                            <td class=""><span class="text-primary"></span> <span class="text-primary">KES {{numberFormat(billDetail.detailAmount)}}</span></td>

                          </tr>
                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Amount Paid</p>
                            </td>
                            <td class=""><span class="text-primary"></span> <span class="text-primary">KES {{numberFormat(billDetail.receiptAmount)}}</span></td>

                          </tr>

                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Outstanding Balance</p>
                            </td>
                            <td class=""><span class="text-primary"></span> <span class="text-primary">KES {{numberFormat(billDetail.billBalance)}}</span></td>

                          </tr>

                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Invoiced By</p>
                            </td>
                            <td class=""><span class="text-primary"></span> <span class="text-primary">{{billDetail.names}} on {{formattedDate(billDetail.dateCreated)}}</span></td>

                          </tr>

                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Inspected By</p>
                            </td>
                            <td class="">
                              <span class="text-primary"></span>
                              <!-- Safely check if statuses[3] exists -->
                              <span v-if="statuses[3]" class="text-primary">{{ statuses[3].issuingOfficer || 'Unknown Officer' }} on{{ formattedDate(statuses[3].dateCreated) || 'Unknown Date' }}</span>
                            </td>
                          </tr>

                          <tr class=" fw-semibold text-uppercase">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Current Stage ({{entry.status}})</p>
                            </td>
                            <td class=""><span class="text-primary"></span> <span class="text-primary">{{entry.stageStatus}}</span></td>

                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-user-pin font-28 mr-15px"></i>
                        <p @click="getChangesDone()" class="fw-semibold mb-0 pb-0">Ownership and Business's Contact information</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Owner Details</th>
                          </thead>
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Full Names</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.fullNames" type="text" class="form-control form-control-sm" placeholder="Owner's full names">
                            </td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's ID</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.ownerID" type="text" class="form-control form-control-sm" placeholder="Owner's ID Number">
                            </td>
                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Phone No.</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.ownerPhone" type="text" class="form-control form-control-sm" placeholder="Owner's Phone No.">
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Email Address</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.ownerEmail" type="text" class="form-control form-control-sm" placeholder="Enter Email">
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                KRA PIN No. (Optional)</p>

                            </td>
                            <td class="">
                              <input type="text" class="form-control form-control-sm" placeholder="Enter KRA Pin Number" >
                            </td>

                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Business Contact Information</th>
                          </thead>
                          <tbody>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Phone No.</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.contactPersonPhone" type="text" class="form-control form-control-sm" placeholder="Owner's Phone No.">
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business's Email Address</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.contactPersonEmail" type="text" class="form-control form-control-sm" placeholder="Enter Email">
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Postal Address</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.postalAddress" type="text" class="form-control form-control-sm" placeholder="Enter Postal ADress">
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Company Phone Number</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.businessPhone" type="text" class="form-control form-control-sm" placeholder="Enter Postal ADress">
                            </td>

                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Contact Person</th>
                          </thead>
                          <tbody>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Full Names</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.contactPersonNames" type="text" class="form-control form-control-sm" placeholder="Owner's Phone No.">
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                ID Number</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.contactPersonIDNo" type="text" class="form-control form-control-sm" placeholder="ID Number">
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Contact Person's Role</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.businessRole" type="text" class="form-control form-control-sm" placeholder="Enter Postal ADress">
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Contact Person's Phone Number</p>

                            </td>
                            <td class="">
                              <input v-model="businessDetails.contactPersonPhone" type="text" class="form-control form-control-sm" placeholder="Enter Postal ADress">
                            </td>

                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center rounded">
                        <i class="bx bxs-wallet font-28 mr-15px"></i>
                        <p class="fw-bold mb-0 ">Billing and Business Categorization Details</p>
                      </div>
                    </div>


                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">SBP Categorization Fees</th>
                          </thead>
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Category</p>
                            </td>
                            <td class="">
                              <select class="form-select form-select-sm" fdprocessedid="fw9x5" @change="getTradeSubCategory(tradeCategory.incomeTypeId)"  v-model="tradeCategory">
                                <option v-for="(item, index) in tradeCategories" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.incomeTypeDescription}}</a></option>
                              </select>


                            </td>
                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Sub category</p>
                            </td>
                            <td class="">

                              <select class="form-select form-select-sm" fdprocessedid="fw9x5"  v-model="tradeSubCategory">
                                <option v-for="(item, index) in tradeSubCategories" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.feeDescription}}  KES {{numberFormat(item.unitFeeAmount)}}</a></option>
                              </select>

                            </td>
                          </tr>

                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business AActivity</p>
                            </td>
                            <td class="">
                              <input v-model="businessDetails.businessDes" type="text" class="form-control form-control-sm" placeholder="Owner's full names" spellcheck="false" data-ms-editor="true">
                            </td>

                          </tr>


                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                SBP Charges</p>
                            </td>

                            <td class="text-right">
                              <h4 class="text-primary fs-4 mb-0 fw-bold"> KES  {{numberFormat(tradeSubCategory.unitFeeAmount)}}</h4>
                            </td>

                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-bordered table-sm">
                          <thead class="table-info">
                          <tr class="text-uppercase table-info">
                            <th colspan="">
                              Other Applicable Charges <i>(-- Select Where Applicable. --)</i>
                            </th>
                          </tr>
                          </thead>
                          <tbody>

                            <tr v-for="(item, index) in tradeSubCategoriesOther" :key="index">
                              <td>
                                <div class="form-check form-check-primary mb-0">
                                  <input
                                      class="form-check-input"
                                      type="checkbox"
                                      :id="'formCheckcolor' + index"
                                      v-model="checkedItems"
                                      :value="item"
                                  >
                                  <label class="form-check-label" :for="'formCheckcolor' + index">
                                    {{ item.feeDescription }} - <strong>KES {{ numberFormat(item.unitFeeAmount) }}</strong>
                                  </label>
                                </div>
                              </td>
                            </tr>



                          </tbody>
                          <tfoot class="table-success">
                          <tr>
                            <th class="text-uppercase">Total({{numberFormat(checkedItems.length)}}) KES {{numberFormat(totalFeeAmount)}}</th>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <!-- penalizing -->
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-bordered table-sm">
                          <thead class="table-info">
                          <tr class="text-uppercase table-info">
                            <th colspan="">
                              Business Penalties <i>(-- Select Where Applicable. --)</i>
                            </th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr>
                            <td>
                              <div class="form-check form-check-primary mb-0">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="penalized"
                                >
                                <label class="form-check-label">
                                  Penalty
                                </label>
                              </div>
                            </td>
                          </tr>



                          </tbody>
                          <tfoot class="table-success">
                          <tr>
                            <th class="text-uppercase">Penalty Charges </th>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>


                    <!-- for a downgrade show this -->

                    <div v-if="Number(entry.billTotal) > Number(tradeSubCategory.unitFeeAmount)" class="col-12">
                      <div class="card bg-danger text-white-50">
                        <div class="card-body">
                          <h5 class="mb-4 text-white text-uppercase"><i class="mdi mdi-block-helper me-3"></i> SBP License Fee Adjustment Notification</h5>
                          <p class="card-text">Please be informed that the SBP license fee for {{Number(entry.year)+1}} will be reduced by KES {{numberFormat(Number(entry.billTotal)-Number(tradeSubCategory.unitFeeAmount))}} compared to the {{Number(entry.year)}} fee.</p>
                        </div>
                      </div>
                      <div class="alert alert-warning border-1 border-warning" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Previous Amount ({{entry.year}}): <strong class="text-primary">KES {{numberFormat(entry.billTotal)}}</strong></h4>
                        <p class="mb-0 text-black">Subcategory: {{entry.feeDescription}}</p>
                      </div>

                      <div class="alert alert-danger border-1 border-danger" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">New Amount ({{Number(entry.year)+1}}): <strong class="text-danger">KES {{numberFormat(tradeSubCategory.unitFeeAmount)}}</strong></h4>
                        <p class="mb-0 text-black">Subcategory: {{tradeSubCategory.feeDescription}}</p>
                      </div>
                    </div>

                    <!-- for maintained  -->
                    <div v-if="Number(entry.billTotal) === Number(tradeSubCategory.unitFeeAmount)" class="col-12">
                      <div class="card bg-primary text-white-50">
                        <div class="card-body">
                          <h5 class="mb-4 text-white text-uppercase"><i class="mdi mdi-information-outline me-3"></i> SBP License Fee Update</h5>
                          <p class="card-text text-capitalize">Please note that the SBP license fee for {{Number(entry.year)+1}} remains the same as in {{entry.year}}, the amounts charged also remain the same.</p>
                        </div>
                      </div>

                      <div class="alert alert-info border-1 border-info" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Amount ({{entry.year}}): <strong class="text-primary">KES {{numberFormat(entry.billTotal)}}</strong></h4>
                        <p class="mb-0 text-black">Previous Subcategory: {{entry.feeDescription}}</p>
                      </div>

                      <div class="alert alert-info border-1 border-info" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Amount ({{Number(entry.year)+1}}): <strong class="text-primary">KES {{numberFormat(tradeSubCategory.unitFeeAmount)}}</strong></h4>
                        <p class="mb-0 text-black">Current Subcategory: {{tradeSubCategory.feeDescription}}</p>
                      </div>
                    </div>

                    <!-- for uphraded business -->
                    <div v-if="Number(entry.billTotal) < Number(tradeSubCategory.unitFeeAmount)" class="col-12">
                      <div class="card bg-success text-white-50">
                        <div class="card-body">
                          <h5 class="mb-4 text-white text-uppercase"><i class="mdi mdi-star-circle-outline me-3"></i> Congratulations on Your Business Upgrade!</h5>
                          <p class="card-text">We’re excited to inform you that the business has been upgraded to a higher category for {{Number(entry.year)+1}}. This enhancement reflects the growth and success of the business, with an adjusted license fee to match the new category.</p>
                        </div>
                      </div>

                      <div class="alert alert-info border-1 border-info" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Previous Amount ({{Number(entry.year)}}): <strong class="text-primary">KES {{numberFormat(entry.billTotal)}}</strong></h4>
                        <p class="mb-0 text-black">Previous Subcategory: {{entry.feeDescription}}</p>
                      </div>

                      <div class="alert alert-success border-1 border-success" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">New Amount ({{Number(entry.year)+1}}): <strong class="text-success">KES {{numberFormat(tradeSubCategory.unitFeeAmount)}}</strong></h4>
                        <p class="mb-0 text-black">New Subcategory: {{tradeSubCategory.feeDescription}}</p>
                      </div>
                    </div>


                  </div>


                </div>

                <div class="col-sm-12 col-md-8">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe
                            :src="mapUrl"
                            width="100%"
                            height="450"
                            style="border:0;"
                            allowfullscreen
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-edit-alt pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">SBP {{Number(entry.year)+1}})({{Number(entry.year)+1}} Licensing Actions</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <h4 class="card-title">Validation actions</h4>
                    </div>


                    <div class="col-6">
                      <div class="form-check mb-3">
                        <input v-model="validationAction"  class="form-check-input" type="radio" name="radioValidation" id="formRadios-validate"  value="approve">
                        <label class="form-check-label" for="formRadios-validate">
                          Approve For Invoicing
                        </label>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-check mb-3 form-radio-danger">
                        <input v-model="validationAction" class="form-check-input" type="radio" name="radioValidation" id="formRadios-archive" value="archive">
                        <label class="form-check-label" for="formRadios-archive">
                          Archive Business
                        </label>
                      </div>
                    </div>

                    <div v-if="validationAction==='archive'" class="col-12 archived-details">
                      <div class="mb-2">
                        <label for="archive_reason">Reason for Archiving:</label>
                        <select v-model="archiveReason" name="archive_reason" id="archive_reason" class="form-select">
                          <option value="" disabled selected>Select a reason</option>
                          <option value="Duplicate Record">Duplicate Record</option>
                          <option value="Business Closed">Business Closed</option>
                          <option value="Test Data">Test Data</option>
                          <option value="Data Obsolete">Data Obsolete</option>
                          <option value="Merged with Another Business">Merged with Another Business</option>
                          <option value="Invalid/Incomplete Details">Invalid/Incomplete Details</option>
                          <option value="Ownership Change">Ownership Change</option>
                          <option value="No Longer Operational">No Longer Operational</option>
                          <option value="Moved to a Different Location">Moved to a Different Location</option>
                          <option value="Compliance Issues">Compliance Issues</option>
                          <option value="Requested by Owner">Requested by Owner</option>
                          <option value="Record Created in Error">Record Created in Error</option>
                          <option value="Redundant Entry">Redundant Entry</option>
                          <option value="other">Other Reason</option>
                        </select>

                      </div>

                      <div v-if="archiveReason ==='other'" class="mb-2 other-reason-cont">
                        <label for="">Enter other Reason</label>
                        <input v-model="otherReason" type="" class="form-control" placeholder="Enter other reason" name="" value="">
                      </div>


                      <div>
                        <button @click="sendOTPMessage()" class="btn btn-danger justify-content-center text-uppercase d-flex align-items-center archive-record w-100 text-center" title="Initiate Payment for Lab Tests">
                          <div>Archive Record</div>
                          <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>

                    </div>

                    <div v-if="validationAction==='approve'" class="col-12 approved-details">
                      <div>
                        <div class="d-flex flex-column">
                          <label class="">Remarks (If Any)</label>
                        </div>
                        <textarea v-model="remarks" name="" id="" cols="30" rows="5" class="form-control mb-2" placeholder="Enter your Remarks"></textarea>
                      </div>
                      <div>

                        <button @click="sendOTPMessage()" data-bs-dismiss="modal" class="btn btn-success justify-content-center text-uppercase d-flex align-items-center approve-invoicing w-100 text-center" title="Initiate Payment for Lab Tests">
                          <div>Generate Invoice</div>
                          <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer d-none">

              <a @click="printReceipt" href="javascript: void(0);" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Receipt
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->


      <!-- View Details -->
      <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade details-modal"
           role="dialog"
           tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
              <div class="d-flex justify-content-between flex-grow-1 align-items-center">
                <div class="d-flex align-items-center">
                  <h5 class="modal-title" id="myLargeModalLabel">{{businessDetails.businessName}} Business Details</h5>
                  <span class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">invoiced for  {{Number(entry.year)+1}}</span>
                </div>
                <div>
                  <a type="button" target="blank" @click="printBill(entry.billNo)" href="javascript: void(0);" class="btn btn-info waves-effect btn-label waves-light text-uppercase mx-3"><i class="dripicons-print label-icon "></i> Print Details</a>
                </div>
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details </p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Business Details</th>
                          </thead>
                          <tbody>
                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <strong>Business Name</strong>
                            </td>
                            <td class="">
                             {{businessDetails.businessName}}
                            </td>
                          </tr>

                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <strong>Sub County</strong>
                            </td>
                            <td class="">
                             {{businessDetails.subCountyName}}
                            </td>
                          </tr>

                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <strong>Ward</strong>
                            </td>
                            <td class="">
                              {{businessDetails.wardName}}
                            </td>
                          </tr>

                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <strong>Zone</strong>
                            </td>
                            <td class="">
                             {{businessDetails.zone}}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>Plot No.</strong>
                            </td>
                            <td class="">
                              {{businessDetails.plotNumber}}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>VAT No.</strong>
                            </td>
                            <td class="">
                              {{businessDetails.kraPin}}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>Physical Address</strong>
                            </td>
                            <td class="">
                              {{businessDetails.physicalAddress}}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>Building Name</strong>
                            </td>
                            <td class="">
                              {{businessDetails.buildingName}}
                            </td>
                          </tr>

                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <strong>Floor Number</strong>
                            </td>
                            <td class="">
                              Ground Floor
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>Stall/Room No.</strong>
                            </td>
                            <td class="">
                              {{businessDetails.roomNo}}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-user-pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Ownership and Business's Contact information</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Owner Details</th>
                          </thead>
                          <tbody>
                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Full Names</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.fullNames}}</p>
                            </td>
                          </tr>
                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Owner's ID</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.ownerID}}</p>
                            </td>
                          </tr>
                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Owner's Phone No.</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.ownerPhone}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Email Address</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.ownerEmail}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA PIN No. (Optional)</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.kraPin}}</p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Business Contact Information</th>
                          </thead>
                          <tbody>
                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Owner's Phone No.</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.ownerPhone}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business's Email Address</p>
                            </td>
                            <td>
                              <p class="mb-0">{{businessDetails.ownerEmail}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal Address</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.postalAddress}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Company Phone Number</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.businessPhone}}</p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Contact Person</th>
                          </thead>
                          <tbody>
                          <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Full Names</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.fullNames}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">ID Number</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.ownerID}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Contact Person's Role</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.businessRole}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Contact Person's Phone Number</p>
                            </td>
                            <td>
                              <p class="mb-0">  {{businessDetails.ownerPhone}}</p>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4 ">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center rounded">
                        <i class="bx bxs-wallet font-28 mr-15px"></i>
                        <p class="fw-bold mb-0 ">Billing and Business Categorization Details</p>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">SBP Categorization Fees</th>
                          </thead>
                          <tbody>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Category</p>
                            </td>
                            <td>
                              <p class="mb-0">{{businessDetails.businessCategory}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Sub category</p>
                            </td>
                            <td>
                              <p class="mb-0">{{businessDetails.businessSubCategory}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business Activity</p>
                            </td>
                            <td>
                              <p class="mb-0">{{businessDetails.businessDes}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">SBP Charges</p>
                            </td>
                            <td class="">
                              <p class="text-primary fs-4 mb-0 fw-bold">KES {{numberFormat(entry.billTotal)}}</p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-bordered table-sm">
                          <thead class="table-info">
                          <tr>
                            <th class="text-uppercase">#</th>
                            <th class="text-uppercase">Other Applicable Charges</th>
                            <th class="text-uppercase">Amount (KES)</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr :key="index" :value="item" v-for="(item, index) in billInfo">
                            <td>{{index+1}}</td>
                            <td>
                              <p class="mb-0">{{item.feeDescription}}</p>
                            </td>
                            <td>
                              <strong>{{numberFormat(item.billTotal)}}</strong>
                            </td>
                          </tr>
                          </tbody>
                          <tfoot class="table-success">
                          <tr>
                            <th colspan="2" class="text-uppercase">Total</th>
                            <th><strong>{{billDetail.detailAmount}}</strong></th>
                          </tr>
                          </tfoot>
                        </table>

                      </div>
                    </div>

                    <!-- for a downgrade show this -->
                    <div v-if="Number(entry.billTotal) > Number(getNewSbpAmount())" class="col-12">
                      <div class="card bg-danger text-white-50">
                        <div class="card-body">
                          <h5 class="mb-4 text-white text-uppercase"><i class="mdi mdi-block-helper me-3"></i> SBP License Fee Adjustment Notification</h5>
                          <p class="card-text">Please be informed that the SBP license fee for {{Number(entry.year)+1}} has reduced by KES {{numberFormat(Number(entry.billTotal)-Number(getNewSbpAmount()))}} compared to the {{Number(entry.year)}} fee.</p>
                        </div>
                      </div>
                      <div class="alert alert-warning border-1 border-warning" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Previous Amount ({{entry.year}}): <strong class="text-primary">KES {{numberFormat(entry.billTotal)}}</strong></h4>
                        <p class="mb-0 text-black">Subcategory: {{entry.feeDescription}}</p>
                      </div>

                      <div class="alert alert-danger border-1 border-danger" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">New Amount ({{Number(entry.year)+1}}): <strong class="text-danger">KES {{numberFormat(getNewSbpAmount())}}</strong></h4>
                        <p class="mb-0 text-black">Subcategory: {{getNewSubCategory()}}</p>
                      </div>
                    </div>

                    <!-- for maintained  -->
                    <div v-if="Number(entry.billTotal) === Number(getNewSbpAmount())" class="col-12">
                      <div class="card bg-primary text-white-50">
                        <div class="card-body">
                          <h5 class="mb-4 text-white text-uppercase"><i class="mdi mdi-information-outline me-3"></i> SBP License Fee Update</h5>
                          <p class="card-text text-capitalize">Please note that the SBP license fee for {{Number(entry.year)+1}} remains the same as in {{Number(entry.year)}}, the amounts charged also remain the same.</p>
                        </div>
                      </div>

                      <div class="alert alert-info border-1 border-info" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Amount ({{Number(entry.year)}}): <strong class="text-primary">KES {{numberFormat(entry.billTotal)}}</strong></h4>
                        <p class="mb-0 text-black">Previous Subcategory: {{entry.feeDescription}}</p>
                      </div>

                      <div class="alert alert-info border-1 border-info" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Amount ({{Number(entry.year)+1}}): <strong class="text-primary">KES {{numberFormat(getNewSbpAmount())}}</strong></h4>
                        <p class="mb-0 text-black">Current Subcategory: {{getNewSubCategory()}}</p>
                      </div>
                    </div>

                    <!-- for uphraded business -->
                    <div v-if="Number(entry.billTotal) < Number(getNewSbpAmount())" class="col-12">
                      <div class="card bg-success text-white-50">
                        <div class="card-body">
                          <h5 class="mb-4 text-white text-uppercase"><i class="mdi mdi-star-circle-outline me-3"></i> Congratulations on Your Business Upgrade!</h5>
                          <p class="card-text">We’re excited to inform you that the business has been upgraded to a higher category for {{Number(entry.year)+1}}. This enhancement reflects the growth and success of the business, with an adjusted license fee to match the new category.</p>
                        </div>
                      </div>

                      <div class="alert alert-info border-1 border-info" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">Previous Amount ({{Number(entry.year)}}): <strong class="text-primary">KES {{numberFormat(entry.billTotal)}}</strong></h4>
                        <p class="mb-0 text-black">Previous Subcategory: {{entry.feeDescription}}</p>
                      </div>

                      <div class="alert alert-success border-1 border-success" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">New Amount ({{Number(entry.year)+1}}): <strong class="text-success">KES {{numberFormat(getNewSbpAmount())}}</strong></h4>
                        <p class="mb-0 text-black">New Subcategory: {{getNewSubCategory()}}</p>
                      </div>
                    </div>


                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe
                            :src="mapUrl"
                            width="100%"
                            height="450"
                            style="border:0;"
                            allowfullscreen
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-check-circle font-28 mr-15px"></i>

                        <p class="fw-semibold mb-0 pb-0">Changes and Updates Done</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">Information On The Business</th>
                          </thead>
                          <tbody>

                          <tr :key="index" :value="item" v-for="(item, index) in changesDone">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">{{item.key}}</p>
                            </td>
                            <td>
                              <p class="mb-0"><strong>From: </strong>{{item.originalName}}</p>
                              <p class="mb-0"><strong>To: </strong>{{item.newName}}</p>
                            </td>
                          </tr>


                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">{{entry.year}} Invoicing Details</th>
                          </thead>

                          <thead class="text-uppercase table-info">
                          <th class="text-uppercase">#</th>
                          <th>Item</th>
                          <th class="text-right">Amount</th>
                          </thead>

                          <tbody>

                          <tr
                              :key="index"
                              :value="item"
                              v-for="(item, index) in billInfo"
                          >
                            <td>{{ index + 1 }}.</td>
                            <!-- Check if item.feeDescription is missing in billInfoNew -->
                            <td :class="{'text-muted': isItemMissing(item.feeDescription)}">
                              <strike v-if="isItemMissing(item.feeDescription)">
                                {{ item.feeDescription }}
                              </strike>
                              <span v-else>{{ item.feeDescription }}</span>
                            </td>
                            <td class="text-right">KES {{ numberFormat(item.billTotal) }}</td>
                          </tr>


                          <tr class="table-info text-uppercase fw-bold">
                            <td colspan="2">Totals</td>

                            <td class="text-right fw-bold">KES {{numberFormat(billDetail.detailAmount)}}</td>
                          </tr>

                          <tr class="text-capitalize">
                            <td colspan="3" class="">Invoicing Date: {{formattedDate(billDetail.dateCreated)}}</td>
                          </tr>

                          <tr class="text-capitalize">
                            <td colspan="3" class="">Invoiced By: {{billDetail.names}}</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-uppercase">{{Number(entry.year)+1}} Invoicing Details</th>
                          </thead>
                          <thead class="text-uppercase table-info">
                          <th class="text-uppercase">#</th>
                          <th>Item</th>
                          <th class="text-right">Amount</th>
                          </thead>
                          <tbody>


                          <tr
                              :key="index"
                              :value="item"
                              v-for="(item, index) in billInfoNew"
                              :class="{'text-success fw-bold': isNewItem(item.feeDescription)}"
                          >
                            <td>{{index + 1}}.</td>
                            <td>{{item.feeDescription}}</td>
                            <td class="text-right">KES {{numberFormat(item.billTotal)}}</td>
                          </tr>



                          <tr class="table-info text-uppercase fw-bold">
                            <td colspan="2">Totals</td>

                            <td class="text-right fw-bold">KES {{numberFormat(billDetailNew.detailAmount)}}</td>
                          </tr>

                          <tr class="text-capitalize">
                            <td colspan="3" class="">Invoicing Date: {{formattedDate(billDetailNew.dateCreated)}}</td>
                          </tr>

                          <tr class="text-capitalize">
                            <td colspan="3" class="">Invoiced By: {{billDetailNew.names}}</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-edit-alt pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">{{Number(entry.year)+1}} Licensing Summary</p>

                      </div>
                    </div>

                    <div class="col-12">

                      <div class="alert alert-warning border-1 border-warning" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">{{entry.year}} Invoice Details</h4>
                        <ul class="mb-0 text-black">
                          <li><strong>Invoice Number:</strong> {{entry.billNo}}</li>
                          <li><strong>Amount:</strong> KES {{numberFormat(entry.billTotal)}}</li>
                          <li><strong>Subcategory:</strong> {{entry.feeDescription}}</li>
                          <li><strong>Items Invoiced:</strong> {{billInfo.length}}</li>
                          <li><strong>Other Charges:</strong> {{Number(billInfo.length)-1}}</li>
                        </ul>
                        <a @click="printBill(entry.billNo)" href="javascript: void(0);" class="btn btn-link text-primary" target="_blank">View {{entry.year}} Invoice</a>
                      </div>

                      <div class="alert alert-success border-1 border-success" role="alert">
                        <h4 class="text-uppercase fw-bold mb-0">{{Number(entry.year)+1}} Invoice Details</h4>
                        <ul class="mb-0 text-black">
                          <li><strong>Invoice Number:</strong> {{billDetailNew.billNo}}</li>
                          <li><strong>Amount:</strong> KES {{numberFormat(getNewSbpAmount())}}</li>
                          <li><strong>Subcategory:</strong> {{getNewSubCategory()}}</li>
                          <li><strong>Items Invoiced:</strong> {{billInfoNew.length}}</li>
                          <li><strong>Other Charges:</strong> {{Number(billInfoNew.length)-1}}</li>
                        </ul>
                        <a @click="printBill(billDetailNew.billNo)" href="javascript: void(0);" class="btn btn-link text-success" target="_blank">View {{Number(entry.year)+1}} Invoice</a>
                      </div>
                      <div class="alert alert-info border-1 border-info" role="alert">
                        <p class="fw-bold text-uppercase">Remarks from Licensing Officer</p>

                        <p class="mb-0 text-black">{{entry.remarks}}</p>

<!--                        <p class="mb-0 text-black">The {{Number(entry.year)+1}} invoice has been updated to reflect a reduced license fee compared to the previous year, along with adjustments to the invoiced items and changes to other applicable charges. For full details, feel free to check out the linked invoices above.</p>
                  -->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer d-none">
              <a @click="printReceipt()" href="javascript: void(0);" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Receipt
              </a>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- View Details -->


      <!-- invoicing done successfully confirmation -->
      <div class="modal fade invoiced-record-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header d-none">
              <h4 class="modal-title text-uppercase" id="myLargeModalLabel">Acknowledgment confirmation</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <!-- Confirmation Icon and Message -->
                <!-- Confirmation Icon and Message -->
                <div class="col-12 d-flex align-items-center justify-content-center pt-3">
                  <div class="p-4 px-5 d-flex align-items-center justify-content-center flex-column pt-5">
                    <div class="flex-shrink-0 align-self-center mb-4">
                      <div class="avatar-md rounded-circle bg-primary mini-stat-icon" title="Action Successful">
                                                <span class="avatar-title rounded-circle bg-success" aria-label="Action Confirmed">
                                                    <i class="bx bx-check-double fs-1" title="Acknowledgment Successful" aria-hidden="true"></i>
                                                </span>
                      </div>
                    </div>
                    <h4 class="text-uppercase text-success text-black modal-title" title="Acknowledgment Confirmed">{{Number(entry.year)+1}} Invoice Generated</h4>
                    <p class="text-center px-5" title="Client notified of the invoice and details">
                      The {{Number(entry.year)+1}} SBP invoice for {{businessDetails.businessName}} was successfully generated for KES {{numberFormat(billDetail.detailAmount)}}. Invoice details were sent to the client via both email and SMS.
                    </p>
                  </div>
                </div>


                <!-- Business and Client Details -->
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table border border-info table-sm table-striped-info table-striped text-black" title="Business and Client Details">
                      <tbody class="text-black text-uppercase">
                      <tr>
                        <td title="Business Name">Business Name</td>
                        <th title="Volvo Office">{{businessDetails.businessName}}</th>
                      </tr>
                      <tr>
                        <td title="Owner's Name">Owner's Name</td>
                        <th title="">{{businessDetails.fullNames}}</th>
                      </tr>
                      <tr>
                        <td title="Client Email">Email</td>
                        <th title="Client Email">{{businessDetails.ownerEmail}}</th>
                      </tr>
                      <tr>
                        <td title="Client Phone Number">Phone Number</td>
                        <th title="Client Phone Number">{{businessDetails.ownerPhone}}</th>
                      </tr>
                      <tr>
                        <td title="Invoice Number">Invoice Number</td>
                        <th title="BLL77575675">{{billDetails.billNo}}</th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Invoice Item Details -->
                <div class="col-12">
                  <div class="table-responsive mt-2">
                    <table class="table table-nowrap border border-dark text-uppercase" title="Invoice Item Details">
                      <thead class="bg-light">
                      <tr>
                        <th style="width: 70px;" title="Item Number">#</th>
                        <th title="Description of Invoice Item">Invoice Item</th>
                        <th class="text-right" title="Amount Charged">Amount</th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr :key="index" :value="item" v-for="(item, index) in billInfo">
                        <td title="Item 1">{{index+1}}</td>
                        <td title="Renewal Fee">{{item.feeDescription}}</td>
                        <td class="text-right">KES {{numberFormat(item.billTotal)}}</td>
                      </tr>


                      </tbody>
                      <tfoot class="border border-dark table-light">
                      <tr class="text-black">
                        <th colspan="2" title="Total Items">{{billInfo.length}} Invoice Items</th>
                        <th class="text-right" title="Total Amount">KES {{billDetails.detailAmount}}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <!-- Summary and Payment Details -->
                <div class="col-8">
                  <div class="table-responsive mt-4">
                    <table class="table border border-success text-uppercase table-sm table-striped table-striped-success">
                      <tbody>
                      <tr>
                        <th>Invoice Date</th>
                        <td class="text-black">{{formattedDate(billDetails.dateCreated)}}</td>
                      </tr>
                      <tr>
                        <th>Invoiced By</th>
                        <td class="text-black">{{billDetails.names}}</td>
                      </tr>
                      <tr>
                        <th>Invoice Amount</th>
                        <td class="text-black fs-2 fw-bold">KES {{billDetails.detailAmount}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Action Buttons -->
                <div class="col-12 d-flex justify-content-end pt-4 text-uppercase">
                  <a @click="printBill(billDetails.billNo)" href="javascript: void(0);" target="_blank" type="button" class="btn btn-primary text-uppercase d-flex align-items-center revertAcknowledgment mx-2 w-100 text-center justify-content-center" id="revertButton" title="Print the generated permit">
                    <div>Permit Invoice</div>
                    <div class="spinner-border text-white ms-2 d-none" style="height: 19px; width: 19px;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </a>
                </div>

              </div>
            </div>



            <div class="modal-footer d-none">
              <button class="btn btn-sm btn-success text-uppercase d-flex align-items-center ms-2 Acknowledge-order" data-bs-toggle="modal" data-bs-target="#payment-modal" title="Initiate Payment for Lab Tests">
                <div>Acknowledge</div>
                <div class="spinner-border text-white ms-2 d-none" style="height: 19px; width: 19px;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.Approve application -->
      </div>
      <!-- end of confirmation -->


      <!-- archived records -->
      <div class="modal fade otp-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <!-- <div class="modal fade otp-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"> -->
        <!-- Modal content here -->


        <div class="modal-dialog  w-100 modal-md modal-dialog-centered modal-full">
          <div class="modal-content w-100 bg-light-blue border-info">
            <div class="modal-header text-black text-uppercase border-0" >
              <h4 class="modal-title">Archive SBP record for Volvo office</h4>
              <button type="button" class="btn-close d-non" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="number-plate-edit-form d-non d-none">
                <div>
                  <div class="col-12">
                    <div class="alert alert-warning border-warning border-2" role="alert">
                      <i class="fas fa-exclamation-triangle"></i>
                      <strong>You are about to edit the number plate of a car.</strong> Please ensure that this action is not fraudulent, as your actions are being logged. <strong>Be certain of what you are doing.</strong> An OTP will be sent to the customer, and it is your responsibility to retrieve it from them for transaction verification.
                    </div>


                  </div>

                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                  <div class="table-responsive mt-4">
                    <h4 class="modal-title mb-2 text-uppercase fw-bold">Invoice Details</h4>
                    <table class="table border border-dark text-uppercase table-sm table-striped align-middle">
                      <tbody>
                      <tr>
                        <th>Invoice Number</th>
                        <td class="text-black">Bll12345</td>
                      </tr>

                      <tr>
                        <th>Paid By</th>
                        <td class="text-black">Kelvin Njuguna 0704549859</td>
                      </tr>

                      <tr>
                        <th>Time of Payment</th>
                        <td class="text-black">12 Jan 2023 11:56 AM</td>
                      </tr>

                      <tr>
                        <th>Vehicle Category</th>
                        <td class="text-black">Saloon Car</td>
                      </tr>
                      <tr>
                        <th>Sub county</th>
                        <td class="text-black">Meru Town</td>
                      </tr>

                      <tr>
                        <th>Zone</th>
                        <td class="text-black">Meru Parking</td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12">
                  <h4 class="modal-title mb-2 text-uppercase fw-bold">Number Plate Editing</h4>
                  <form action="">
                    <table class="table border border-info text-uppercasetable-striped align-middle">
                      <tbody>
                      <tr>

                        <td colspan="2" class="text-black fw-bold">
                          <label for="">Entered Number Plate</label>
                          <h4 class="text-danger">
                            KAD123Y
                          </h4>
                        </td>
                      </tr>

                      <tr>

                        <td colspan="2" class="text-black">
                          <div>
                            <label for="">Edit Number Plate to <strong class="text-danger">*</strong></label>
                            <input type="" class="form-control border-bold border-2 border-info" placeholder="Enter New  Plate Number" name="" value="" required="" spellcheck="false" data-ms-editor="true">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="fw-bold text-dark">
                            <label for="" class="">Reason For Editing <strong class="text-danger">*</strong></label>
                            <textarea name="" class="form-control border-bold border-2 border-info" placeholder="Enter Reason" id="" required="" spellcheck="false" data-ms-editor="true"></textarea>
                          </div>
                        </td>
                      </tr>


                      </tbody>
                    </table>

                    <btn target="_blank" type="button" class="btn btn-primary w-100 mt-2 waves-effect waves-light text-uppercase initiate-plate-edit">
                      Initiate
                    </btn>
                  </form>
                </div>
              </div>

              <div class="otp-entry">
                <div class="alert alert-info border-info border-2" role="alert">
                  <h4 class="alert-heading text-black text-uppercase">OTP Verification</h4>



                  <p>An OTP has been sent to your phone: <strong>{{maskPhoneNumber(sessionPhoneNumber)}}</strong>. Please enter the OTP to confirm that you want to archive this record.</p>                                    <hr class="text-black border-2 border-black border-info text-info">
                  <p class="mb-0"><Strong>Archive Reason</Strong></p>
                  <p>Duplicate Entry</p>
                  <div class="hstack gap-3">
                    <input v-model="otpCodes" class="form-control me-auto border-black" type="text" placeholder="Enter OTP" aria-label="Add your item here..." spellcheck="false" data-ms-editor="true">

                    <button @clicked="sendOTPMessage()" type="button" class="btn btn-warning text-dark fw-bold text-nowrap text-uppercase">

                      <div>Resend OTP</div>
                      <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>

                  </div>

                  <!-- message sent to customer -->

                  <p class=" d-none">"Dear [Customer Name], your number plate change from [Previous Number Plate] to [New Number Plate] is in process. Your OTP for finalizing the changes is [OTP]. If you did not initiate this request, please report it immediately. Thank you!"
                    <!-- dont show this message anywhere but it should go to the customer -->
                  </p>
                </div>

                <div v-if="message!=null" class="alert alert-danger" role="alert">
                  {{message}}
                </div>

                <button @click="verifyOTP()" class="btn btn-success fw-bold mt-4 text-uppercase w-100 d-flex justify-content-center align-items-center text-center confirm-archive" title="Initiate Payment for Lab Tests">
                  <div>confirm</div>
                  <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>

              </div>

              <!-- show this for succesfully archived record-->

              <div class="d-none p-4 pb-1 archive-done px-1 d-flex align-items-center justify-content-center flex-column pt-1">
                <div class="flex-shrink-0 align-self-center mb-4">
                  <div class="avatar-md rounded-circle bg-primary mini-stat-icon" title="Action Successful">
                                        <span class="avatar-title rounded-circle bg-success">
                                            <i class="bx bx-archive fs-1" title="Records Archived"></i>
                                        </span>
                  </div>
                </div>
                <h4 class="text-uppercase text-dark modal-title fw-bold" title="Change Confirmed">Business Archived</h4>
                <p class="text-center px-5" title="Record Archiving Confirmation">
                  The business named X has been archived successfully.
                </p>

                <div class="w-100 mt-2">
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-100 btn-close-btn-otp text-uppercase fw-bold">
                    Close
                  </button>
                </div>

                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div> -->

              </div>

              <!-- show this for a failed attempt -->
              <div class="d-none p-4 pb-1 archiving-fail px-1 d-flex align-items-center justify-content-center flex-column pt-1">
                <div class="flex-shrink-0 align-self-center mb-4">
                  <div class="avatar-md rounded-circle bg-danger mini-stat-icon" title="Action Failed">
                                        <span class="avatar-title rounded-circle bg-danger">
                                            <i class="mdi mdi-close fs-1" title="Archiving Failed"></i>
                                        </span>
                  </div>
                </div>
                <h4 class="text-uppercase text-danger modal-title fw-bold" title="Archiving Failed">Archiving Failed</h4>
                <p class="text-center px-5" title="Business Record Archiving Failed">
                  Unfortunately, the attempt to archive the business record for <strong>“Business XYZ Ltd.”</strong> was unsuccessful. Please try again or contact support for assistance.
                </p>

                <div class="w-100 mt-2">
                  <button type="button" class="btn btn-info waves-effect w-100 btn-close-btn text-uppercase fw-bold">Retry</button>
                  <h6 class="text-center my-2">- OR -</h6>
                  <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-100 btn-close-btn text-uppercase fw-bold">Close</button>
                </div>
              </div>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of archived records -->


      <footer class="footer">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                County Government
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay"></div>

  </body>

</template>

<script>
/* eslint-disable */
import NavigationBar from "@/components/Navigation";
import ToolBar from "@/components/sbp/bulk/ToolBarBulk";
import router from "@/router";
import {authUrl, biller, execute, executeDownload, getRight, trade} from "@/api";
import moment from 'moment'
import approved from "@/components/sbp/Approved.vue";

export default {
  name: "CertBizOngoing",
  components: {ToolBar, NavigationBar},
  data() {
    return {
      indexPosition:'',
      loadingIndex: null,
      otp:'',
      otpCodes:'',
      sessionPhoneNumber:'',
       search:'',
      loading: false,
      // set the initial current page as 1
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      summary: {
        count: 0,
        total: 0
      },
      totalAmount: 0,
      selectedItem: null,
      valueItem: "",
      itemList: [{
        id: null,
        value: null
      }],
      wards: [{
        wardID: "",
        wardName: ""
      }],
      wardsFilter: [],
      filter: '',
      dateFrom: '',
      dateTo: '',
      message: null,
      alert: {
        success: false,
        info: false,
        failed: false
      },
      billInfoTotal: 0,
      billNo: '',
      businessDetailsOriginal: {
        id: null,
        businessID: null,
        businessName: null,
        subCountyID: null,
        subCountyName: null,
        wardID: null,
        wardName: null,
        plotNumber: null,
        physicalAddress: null,
        buildingName: null,
        buildingOccupancy: null,
        floorNo: null,
        roomNo: null,
        premiseSize: null,
        numberOfEmployees: null,
        tonnage: null,
        businessDes: null,
        businessCategory: null,
        businessSubCategory: null,
        incomeTypeID: null,
        feeID: null,
        businessEmail: null,
        postalAddress: null,
        postalCode: null,
        businessPhone: null,
        contactPersonNames: null,
        contactPersonIDNo: null,
        businessRole: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        fullNames: null,
        ownerID: null,
        ownerPhone: null,
        ownerEmail: null,
        kraPin: null,
        createdBy: null,
        createdByIDNo: null,
        dateCreated: null,
        lat: 0.0,
        lng: 0.0
      },
      businessDetails: {
        id: null,
        businessID: null,
        businessName: null,
        subCountyID: null,
        subCountyName: null,
        wardID: null,
        wardName: null,
        plotNumber: null,
        physicalAddress: null,
        buildingName: null,
        buildingOccupancy: null,
        floorNo: null,
        roomNo: null,
        premiseSize: null,
        numberOfEmployees: null,
        tonnage: null,
        businessDes: null,
        businessCategory: null,
        businessSubCategory: null,
        incomeTypeID: null,
        feeID: null,
        businessEmail: null,
        postalAddress: null,
        postalCode: null,
        businessPhone: null,
        contactPersonNames: null,
        contactPersonIDNo: null,
        businessRole: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        fullNames: null,
        ownerID: null,
        ownerPhone: null,
        ownerEmail: null,
        kraPin: null,
        createdBy: null,
        createdByIDNo: null,
        dateCreated: null,
        lat: 0.0,
        lng: 0.0
      },
      billDetail: {
        id: null,
        billNo: null,
        incomeTypeID: null,
        incomeTypeDescription: null,
        costCenterNo: null,
        accountNo: null,
        description: null,
        feeID: null,
        feeDescription: null,
        detailAmount: null,
        receiptAmount: null,
        billBalance: null,
        wardID: null,
        subCountyID: null,
        subCountyName: null,
        wardName: null,
        status: null,
        dateCreated: null,
        zone: null,
        departmentID: null,
        department: null,
        idNo: null,
        phoneNumber: null,
        names: null,
        customerPhoneNumber: null,
        category: null
      },
      billDetailNew: {
        id: null,
        billNo: null,
        incomeTypeID: null,
        incomeTypeDescription: null,
        costCenterNo: null,
        accountNo: null,
        description: null,
        feeID: null,
        feeDescription: null,
        detailAmount: null,
        receiptAmount: null,
        billBalance: null,
        wardID: null,
        subCountyID: null,
        subCountyName: null,
        wardName: null,
        status: null,
        dateCreated: null,
        zone: null,
        departmentID: null,
        department: null,
        idNo: null,
        phoneNumber: null,
        names: null,
        customerPhoneNumber: null,
        category: null
      },
      billDetails: {
        id: null,
        billNo: null,
        incomeTypeID: null,
        incomeTypeDescription: null,
        costCenterNo: null,
        accountNo: null,
        description: null,
        feeID: null,
        feeDescription: null,
        detailAmount: null,
        receiptAmount: null,
        billBalance: null,
        wardID: null,
        subCountyID: null,
        subCountyName: null,
        wardName: null,
        status: null,
        dateCreated: null,
        zone: null,
        departmentID: null,
        department: null,
        idNo: null,
        phoneNumber: null,
        names: null,
        customerPhoneNumber: null,
        category: null
      },
      businesses: [
        {
          id: null,
          billNo: null,
          businessID: null,
          businessName: null,
          businessSubCategory: null,
          duration: null,
          billTotal: null,
          receiptAmount: null,
          billBalance: null,
          startDate: null,
          endDate: null,
          statusID: null,
          status: null,
          printable: null,
          issuingOfficer: null,
          designation: null,
          dateOfIssue: null,
          stageStatus: null
        }],
      entries: [{
        remarks:null,
        subCountyName:null,
         wardName:null,
         zone: null,
          businessID: null,
          renewalStatus: null,
          businessName: null,
          owner: null,
          ownerPhone: null,
          registeredOn: null,
          registeredBy: null,
          feeDescription: null,
          billNo: null,
          changesCount: null,
          billTotal: null,
          receiptAmount: null
        }],
      feesAndCharges: {
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc: null,
        costCenterNo: null,
        zone: null,
        typeDescription: null,
        prorated: null,
        duration: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      },
      receiptDetails: {
        id: null,
        receiptNo: null,
        billNo: null,
        detailAmount: null,
        receiptAmount: null,
        billBalance: null,
        costCenterNo: null,
        accountNo: null,
        incomeTypeDescription: null,
        feeID: null,
        feeDescription: null,
        wardID: null,
        subCountyID: null,
        subCountyName: null,
        wardName: null,
        currency: null,
        source: null,
        transactionCode: null,
        paidBy: null,
        dateCreated: null,
        dateModified: null,
        createdBy: null,
        modifiedBy: null,
        isActive: null,
        status: null,
        zone: null,
        departmentID: null,
        department: null,
        idNo: null,
        phoneNumber: null,
        names: null,
        customerPhoneNumber: null,
        description: null,
        category: null
      },
      receiptInfos: [
        {
          id: null,
          receiptNo: null,
          billNo: null,
          feeID: null,
          feeDescription: null,
          billTotal: null,
          receiptAmount: null,
          billBalance: null,
          customer: null,
          clientPhoneNo: null,
          description: null,
          receiptDate: null,
          printCount: null,
          wardID: null,
          subCountyID: null,
          subCountyName: null,
          wardName: null,
          dateCreated: null,
          dateModified: null,
          createdBy: null,
          modifiedBy: null,
          printedBy: null,
          updated: null,
          isActive: null,
          status: null,
          zone: null,
          departmentID: null,
          department: null,
          idNo: null,
          phoneNumber: null,
          names: null,
          customerPhoneNumber: null,
          category: null
        }],
      tradeCategories: [{
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      }],
      tradeCategory:{
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix: null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategoryID: null,
        zoneCategory: null
      },
      tradeSubCategories: [
        {
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc: null,
          costCenterNo: null,
          zone: null,
          typeDescription: null,
          prorated: null,
          duration: null
        }
      ],
      billInfo: [
        {
          id: null,
          billNo: null,
          feeID: null,
          feeDescription: null,
          fiscalYear: null,
          paymentCode: null,
          customer: null,
          description: null,
          periodID: null,
          wardID: null,
          subCountyID: null,
          subCountyName: null,
          wardName: null,
          createdBy: null,
          billTotal: null,
          receiptAmount: null,
          billBalance: null,
          status: null,
          dateCreated: null,
          zone: null,
          departmentID: null,
          department: null,
          idNo: null,
          phoneNumber: null,
          names: null,
          customerPhoneNumber: null,
          category: null,
          incomeTypePrefix: null,
          quantity: null
        }
      ],
      billInfoNew: [
        {
          id: null,
          billNo: null,
          feeID: null,
          feeDescription: null,
          fiscalYear: null,
          paymentCode: null,
          customer: null,
          description: null,
          periodID: null,
          wardID: null,
          subCountyID: null,
          subCountyName: null,
          wardName: null,
          createdBy: null,
          billTotal: null,
          receiptAmount: null,
          billBalance: null,
          status: null,
          dateCreated: null,
          zone: null,
          departmentID: null,
          department: null,
          idNo: null,
          phoneNumber: null,
          names: null,
          customerPhoneNumber: null,
          category: null,
          incomeTypePrefix: null,
          quantity: null
        }
      ],
      tradeSubCategoriesOther: [
        {
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc: null,
          costCenterNo: null,
          zone: null,
          typeDescription: null,
          prorated: null,
          duration: null
        }
      ],
      tradeSubCategory: {
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc: null,
          costCenterNo: null,
          zone: null,
          typeDescription: null,
          prorated: null,
          duration: null
        },
      statuses: [{
        id: null,
        businessID: null,
        statusID: null,
        status: null,
        description: null,
        dateCreated: null,
        completed: true,
        billNo: null,
        comments: null,
        issuingOfficer: null,
        updatedDate: null,
        location: null
      }],
      subCounties:[],
      subCountiesFilter:[],

      subCounty: {
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },
      subCountyFilter: {
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },
      ward:{
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },
      wardFilter:{
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },
      zone:null,
      zones: [],
      zonesFilter: [],
      floors:[],
      floor:{},
      entry: {
        id: null,
        billNo: null,
        businessID: null,
        businessName: null,
        duration: null,
        billTotal: null,
        receiptAmount: null,
        billBalance: null,
        startDate: null,
        endDate: null,
        statusID: null,
        status: null,
        printable: null,
        issuingOfficer: null,
        designation: null,
        dateOfIssue: null,
        stageStatus: null,
        comments: null,
        incomeTypeId: null,
        feeID: null,
        feeDescription: null,
        subCountyName: null,
        wardName: null,
        dateCreated: null,
        subCountyID: null,
        wardID: null,
        code: null,
        brimsCode: null,
        plotNumber: null,
        fiscalYear: null,
        printCount: null,
        receiptedBy: null,
        renewalStatus: null,
        owner: null,
        ownerPhone: null,
        registeredOn: null,
        registeredBy: null,
        changesCount: null,
        year: null,
        changesDone:null,
        remarks:null
      },
      precheckedFeeIDs: [], // Store the prechecked feeIDs from billInfo
      checkedItems: [], // Array to store checked items
      totalFeeAmount: 0, // To hold the calculated total fee amount
      validationAction: "approve",
      archiveReason:'',
      otherReason:'',
      penalized: false,


      /*Generate Bill*/
      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,
      billItem:[],
      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },

      feesAndChargeItems:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        customer: null,
        quantity: 1
      }],
      changesDone:[
          {
            key: "businessName",
        originalName:"Osongo Buzna",
        newName:"Osambama"
      }
      ],
      billingChange:'NORMAL',
      subCountyNameFilter:'',
      wardNameFilter:'',
      zoneNameFilter:'',
      statusCounts:'',
      totalPaidAmount:0,
      remarks:'',
      printable:'',
    }

  },
  watch: {
    //pagination code
    // define a watcher for the pageSize property
    pageSize(newPageSize) {
      this.getPreviousRegister()
      // get the total number of items from your data source (you can change this according to your logic)
      const totalItems = this.summary.count;
      // calculate the new total pages by dividing the total items by the new page size and rounding up
      const newTotalPages = Math.ceil(totalItems / newPageSize);
      // update the totalPages data property with the new value
      this.totalPages = newTotalPages;
      // reset the current page to 1
      this.currentPage = 1;

    },
    search(){
      this.getPreviousRegister()
    },
    checkedItems(newCheckedItems) {
      // Calculate the totalFeeAmount whenever checkedItems changes
      this.totalFeeAmount = newCheckedItems.reduce((sum, item) => sum + parseFloat(item.unitFeeAmount || 0), 0);
    },
    ward(){
      this.businessDetails.wardName = this.ward.wardName
      this.businessDetails.wardID = this.ward.wardID
    },
    zone(){
      this.businessDetails.zone = this.zone
    },
    subCountyFilter(){
      this.wardFilter ={}
      this.zoneNameFilter =''
     this.getPreviousRegister()
    },
    wardFilter(){
      this.zoneNameFilter =''
     this.getPreviousRegister()
    },
    zoneNameFilter(){
     this.getPreviousRegister()
    }

  },
  computed: {
    approved() {
      return approved
    },
    //pagination code
    // calculate the start index of the items for the current page
    startIndex() {
      return (this.currentPage - 1) * this.pageSize;
    },
    // calculate the end index of the items for the current page
    endIndex() {
      return this.currentPage * this.pageSize;
    },

    totalReceiptAmount() {
      return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.receiptAmount), 0));
    },
    totalBillBalance() {
      return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.billBalance), 0));
    },
    totalDetailAmount() {
      return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.detailAmount), 0));
    },
    iframeSrc() {
      return 'https://maps.google.com/maps?q=' + this.businessDetails.lat + ',' + this.businessDetails.lng + '&t=&z=13&ie=UTF8&iwloc=&output=embed';
    },
    mapUrl() {
      const encodedBusinessName = encodeURIComponent(this.businessDetails.businessName);
      return `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d${this.businessDetails.lng}!3d${this.businessDetails.lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2s${encodedBusinessName}!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske`;
    }
  },
  mounted() {


   this.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")
    this.dateFrom = moment().startOf('month').format('YYYY-MM-DD')
    this.dateTo = moment().format('YYYY-MM-DD')

    this.getPreviousRegister()
   // this.getTradeCategory()

    this.$watch('dateFrom', function (newValue) {
      if (this.dateFrom === '' || this.dateTo === '') {

      } else {
        this.getPreviousRegister()
      }

    })
    this.$watch('dateTo', function (newValue) {
      if (this.dateFrom === '' || this.dateTo === '') {

      } else {
        this.getPreviousRegister()
      }
    })

    this.getSubCountiesFilter()
    this.menuAccess()
  },
  methods: {
    isItemMissing(feeDescription) {
      return !this.billInfoNew.some(info => info.feeDescription === feeDescription);
    },
    isNewItem(feeDescription) {
      return !this.billInfo.some(info => info.feeDescription === feeDescription);
    },
    maskPhoneNumber(input) {
      if (!input || typeof input !== 'string') {
        return input; // Return as is if input is null, undefined, or not a string
      }

      const digits = input.replace(/\D/g, '');

      if (digits.length > 4) {
        return input.slice(0, 2) + input.slice(2, -2).replace(/\d/g, '*') + input.slice(-2);
      }

      return input; // Return as is if not a phone number or too short
    },

    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Paid SBP");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },
    getRight(type){
      return getRight(type)
    },
    //pagination code
    pagination(currentPage, totalPages, maxVisibleButtons) {
      //initialize an empty array for the pages
      let pages = [];
      //calculate the start and end indices of the visible buttons
      let start = currentPage - Math.floor(maxVisibleButtons / 2);
      let end = currentPage + Math.floor(maxVisibleButtons / 2);
      //adjust the start and end indices if they are out of bounds
      if (start < 1) {
        start = 1;
        end = start + maxVisibleButtons - 1;
        if (end > totalPages) {
          end = totalPages;
        }
      }
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisibleButtons + 1;
        if (start < 1) {
          start = 1;
        }
      }
      //loop through the start and end indices and push the pages to the array
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      //add an ellipsis at the beginning if there are more pages before the start index
      if (start > 1) {
        pages.unshift("...");
      }
      //add an ellipsis at the end if there are more pages after the end index
      if (end < totalPages) {
        pages.push("...");
      }
// return the array of pages
      return pages;
    },
    // define a method to go to the previous page
    prevPage() {
      // check if the current page is not the first page
      if (this.currentPage > 1) {
        // decrement the current page by 1
        this.currentPage--;
        this.getPreviousRegister()
      }
    },
    // define a method to go to the next page
    nextPage() {
      // check if the current page is not the last page
      if (this.currentPage < this.totalPages) {
        // increment the current page by 1
        this.currentPage++;
        this.getPreviousRegister()
      }
    },
    // define a method to go to a specific page
    goToPage(page) {
      // check if the page is valid and not equal to the current page
      if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
        // set the current page to the given page
        this.currentPage = page;
        this.getPreviousRegister()
      }
    },

    //number format
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    gotTo(route) {
      router.push(route)
    },
    timelineCarousel() {

      $("#timeline-carousel").owlCarousel({
        items: 1,
        loop: !1,
        margin: 0,
        nav: !0,
        navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
        dots: !1,
        responsive: {
          576: {
            items: 2
          },
          768: {
            items: 4
          }
        },
        startPosition: -1 // display the last item first
      });

    },
    printTradePermit(businessID) {

      const data = new FormData();
      data.append("function", "printTradePermit");
      data.append("businessID", businessID);
      execute(data, trade)
          .then((res) => {
            if (res.data.success) {

              localStorage['params'] = JSON.stringify({
                businessID: businessID
              })
              const routeData = this.$router.resolve({name: 'trade'});
              window.open(routeData.href, '_blank');

            } else {
              this.businessNotFound = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    getHumanDate(dateData){
      //  const res = dateData.split(" ");
      if(dateData=="-"){
        return "-";
      }else {
        const dateObject = new Date(Date.parse(dateData));
        return dateObject.toDateString();
      }


    },
    getApplicationsPaginated(search){
      this.loading = true
      this.businesses.splice(0)
      const data = new FormData();
      data.append("function", "paidSBP")
      data.append("search", search)
      data.append("dateFrom", this.dateFrom)
      data.append("dateTo", this.dateTo)
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      execute(data, trade)
          .then((res) => {
            this.loading = false
            if (res.data.success) {

              this.totalItems = res.data.data.totalItems.recordCount
              this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

              this.businesses = res.data.data.businesses
              this.totalAmount = this.businesses.reduce((acc, cur) => {
                let totalAmount = Number(cur.receiptAmount);
                if (!isNaN(totalAmount)) {
                  return acc + totalAmount;
                } else {
                  return acc;
                }
              }, 0);

            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });
    },
    downloadApplicationsPaginated(){
      this.download ="Please wait.."
      const data = new FormData();
      data.append("function", "downloadPaidSBP");
      data.append("dateFrom", this.dateFrom)
      data.append("dateTo", this.dateTo)
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      executeDownload(data,trade).then(response => {
        this.download ="Download Report"
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = this.dateFrom+' to '+ this.dateTo+" Businesses.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getBusinessDetails(id,index) {
      this.indexPosition = index
      this.changesDone.splice(0)
      this.billInfo.splice(0);
      this.statuses.splice(0);
      const data = new FormData();
      data.append("function", "getBusinessDetails");
      data.append("id", id);

      execute(data, trade)
          .then((res) => {
            if (res.data.success) {
              this.statuses = res.data.data.statuses;
              this.businessDetails = res.data.data.business;

              // Deep clone `businessDetails` to create an independent `businessDetailsOriginal`
              this.businessDetailsOriginal = JSON.parse(JSON.stringify(this.businessDetails));

              this.getSubCounties();
              this.entry = res.data.data.entries;

              if (res.data.data.entries.changesDone) {
                const parsedChanges = JSON.parse(res.data.data.entries.changesDone);
                if (Array.isArray(parsedChanges)) {
                  this.changesDone = parsedChanges;
                } else {
                  this.changesDone = []; // Fallback if it's not an array
                }
              } else {
                this.changesDone = []; // Fallback if it's null
              }

              this.billDetail = res.data.data.bill.billDetails;
              this.billInfo = res.data.data.bill.billInfo;

              if (res.data?.data?.billNew) {
                this.billDetailNew = res.data.data.billNew.billDetails;
                this.billInfoNew = res.data.data.billNew.billInfo;
              }

              this.feesAndCharges = res.data.data.feesAndCharge;
              this.receiptDetails = res.data.data.receipt.receiptDetails;
              this.receiptInfos = res.data.data.receipt.receiptInfos;

              this.getTradeCategory();
              this.getTradeCategoryOther();
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },

    formattedDate(date) {
      return moment(date).format('DD MMM YYYY hh:mm A') + ' (' + moment(date).fromNow() + ')'
    },
    printBill(billNo) {

      if (this.billDetails.printable ==='false') {
        alert('The invoice is not ready for printing as it has not been approved yet. Please check back later or contact support for assistance.');
        return
      }else {
        localStorage['params'] = JSON.stringify({
          billNo: billNo
        })
        const routeData = this.$router.resolve({name: 'bill'});
        window.open(routeData.href, '_blank');
      }


    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    getTradeCategory() {
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "SBP");
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {

              this.tradeCategories = res.data.data.incomeTypes

              //preselection
              this.tradeCategories.forEach((value, index) => {
                if (this.businessDetails.businessCategory === value.incomeTypeDescription) {
                  this.tradeCategory = this.tradeCategories[index];
                  this.getTradeSubCategory(this.tradeCategories[index].incomeTypeId)
                }
              });




            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getTradeSubCategory(incomeTypeId) {
      this.tradeSubCategories.splice(0)
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId", incomeTypeId);
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.tradeSubCategories = res.data.data.feesAndCharges

              //preselection
              this.tradeSubCategories.forEach((value, index) => {
                if (this.businessDetails.businessSubCategory === value.feeDescription) {
                  this.tradeSubCategory = this.tradeSubCategories[index];
                }
              });

            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    selectedTradeSubCategory(event) {
      const data = JSON.parse(event.target.value)
      this.getApplicationsPaginated(data.feeId)
      //set fee and charges
      /*    this.selectedFeeAndCharge(event)

          const data = JSON.parse(event.target.value)
          this.trade.businessDetails.businessCategory = data.accountDesc
          this.trade.businessDetails.businessSubCategory =  data.feeDescription
          this.trade.amount =  data.unitFeeAmount
          this.trade.businessDetails.feeID = data.feeId;*/

    },
    selectedSubCounty(event) {
      const data = JSON.parse(event.target.value)
      this.getWards(data.subCountyID)
      this.getApplicationsPaginated(data.subCountyName)

    },
    selectedWard(event) {
      const data = JSON.parse(event.target.value)
      this.getApplicationsPaginated(data.wardName)
    },
    getPreviousRegister(){
      this.loading = true
      this.entries.splice(0)
      const data = new FormData();
      data.append("function", "getPreviousRegister")
      data.append("dateFrom", this.dateFrom)
      data.append("dateTo", this.dateTo)
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      data.append("keyword", this.search);
      data.append("subCountyName", this.subCountyFilter.subCountyName);
      data.append("wardName", this.wardFilter.wardName);
      data.append("zone", this.zoneNameFilter);
      execute(data, trade)
          .then((res) => {
            this.loading = false
            if (res.data.success) {

              this.entries = res.data.data.entries

              this.totalPaidAmount = this.entries.reduce((sum, entry) => {
                // Ensure that the receiptAmount is a number before adding
                return sum + (Number(entry.receiptAmount) || 0); // Defaults to 0 if receiptAmount is not a valid number
              }, 0);

              this.statusCounts = res.data.data.statusCounts.statusCounts
              this.totalItems = res.data.data.totalItems.recordCount
              this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });
    },
    getSubCounties(){
      this.message = null
      const data = new FormData();
      data.append("function", "getSubCounties");
      execute(data,authUrl).then((res) =>{
        if (res.data.success) {
          this.subCounties = res.data.data.subCounties

          //preselection
          this.subCounties.forEach((value, index) => {
            if (this.businessDetails.subCountyID === value.subCountyID) {
              this.subCounty = this.subCounties[index];
            }
          });

          this.getWards(this.subCounty.subCountyID)
          this.getZones(this.subCounty.subCountyID)
          this.getFloor()

        }else{
          this.message =res.data.message
        }
      }).catch((e) => {
        //alert(e.message);
        this.message =e.message
      });
    },
    getWards(subCountyID){

      this.businessDetails.subCountyName = this.subCounty.subCountyName
      this.businessDetails.wardName = this.subCounty.wardName

      this.message = null
      const data = new FormData();
      data.append("function", "getWards");
      data.append("subCountyID",subCountyID);
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.wards = res.data.data.wards

              this.wards.forEach((value, index) => {
                if (this.businessDetails.wardID === value.wardID) {
                  this.ward = this.wards[index];
                }
              });

              this.getZones(subCountyID)
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getZones(subCountyID){
      this.zones.splice(0)
      const data = new FormData();
      data.append("function", "getZones");
      data.append("subCountyID", subCountyID);
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.zones = res.data.data.zones
              this.zones.forEach((value, index) => {

                if (this.businessDetails.zone.includes(value.zone)) {
                  this.zone = this.zones[index].zone
                }

              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getFloor(){
      const data = new FormData();
      data.append("function", "getFloor");
      execute(data,trade)
          .then((res) =>{
            if (res.data.success) {
              this.floors =res.data.data.floors

              this.floors.forEach((value, index) => {
                if (this.businessDetails.floorNo === value.floorNo) {
                  this.floor = this.floors[index];
                }
              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getTradeCategoryOther(){
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "SBP");
      data.append("keyword", "Other");
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.getTradeSubCategoryOther(res.data.data.incomeTypes[0].incomeTypeId)

            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getTradeSubCategoryOther(incomeTypeId){
      this.tradeSubCategoriesOther.splice(0); // Clear existing entries
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId", incomeTypeId);

      execute(data, biller)
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              this.tradeSubCategoriesOther = res.data.data.feesAndCharges;

              console.log('Bill Info:', this.billInfo);
              console.log('Trade Sub Categories Other:', this.tradeSubCategoriesOther);

              // Initialize checkedItems
              if (!this.checkedItems) {
                this.checkedItems = [];
              } else {
                this.checkedItems.splice(0); // Clear existing items
              }

              // Match logic with normalization
             /* this.tradeSubCategoriesOther.forEach((item) => {
                const match = this.billInfo.find((bill) => String(bill.feeID) === String(item.feeId));
                console.log('Checking item:', item, 'Match:', match);
                if (match) {
                  this.checkedItems.push(item);
                }
              });*/

              this.tradeSubCategoriesOther.forEach((item) => {

                if(item.feeDescription.includes("Renewal")){
                  this.checkedItems.push(item);
                }
                const match = this.billInfo.find((bill) => String(bill.feeID) === String(item.feeId));
                console.log('Checking item:', item, 'Match:', match);
                if (match) {
                  if(!item.feeDescription.includes("Registration")){
                    this.checkedItems.push(item);
                  }
                }
              });
              this.totalFeeAmount = this.checkedItems.reduce((sum, item) => sum + parseFloat(item.unitFeeAmount || 0), 0);

              console.log('Checked Items:', this.checkedItems);
            } else {
              this.message = res.data.message;
            }
          })
          .catch((e) => {
            this.message = e.message;
          });
    },
    updateInvoicedStatus(){


      if (Number(this.entry.billTotal) > Number(this.tradeSubCategory.unitFeeAmount)) {
        this.printable = 'false'
        this.billingChange = 'DOWNGRADE';
      } else if (Number(this.entry.billTotal) < Number(this.tradeSubCategory.unitFeeAmount)) {
        this.printable = 'false'
        this.billingChange = 'UPGRADE';
      } else {
        this.printable = 'true'
        this.billingChange = 'NORMAL';
      }



      this.getChangesDone()
      const data = new FormData();
      data.append("function", "updateInvoicedStatus");
      data.append("id", this.entry.id);
      data.append("newBillNo", this.billDetails.billNo);
      data.append("changesDone", JSON.stringify(this.changesDone));
      data.append("businessID", this.businessDetails.businessID);
      data.append("billingChange", this.billingChange);
      data.append("changesCount", this.changesDone.length);
      data.append("remarks", this.remarks);
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      execute(data,trade).then((res) =>{
        if (res.data.success) {
          this.getPreviousRegister()
        }else{
          this.message =res.data.message
        }
      }).catch((e) => {
        //alert(e.message);
        this.message =e.message
      });
    },
    archiveInvoicedStatus(){
      this.closeAllModals()
      const data = new FormData();
      data.append("function", "archiveInvoicedStatus");
      data.append("id", this.entry.id);
      data.append("renewalStatus", "ARCHIVED");
      data.append("archiveReason", `${this.archiveReason} ${this.otherReason}`);
      execute(data,trade).then((res) =>{
        if (res.data.success) {
          this.getPreviousRegister()
        }else{
          this.message =res.data.message
        }
      }).catch((e) => {
        //alert(e.message);
        this.message =e.message
      });
    },
    getNewSbpAmount() {
      const matchingBill = this.billInfoNew.find(bill => bill.customer?.includes("Business ID"));
      return matchingBill ? matchingBill.billTotal : 0; // Return `billTotal` or `null` if not found
    },
    getNewSubCategory() {
      const matchingBill = this.billInfoNew.find(bill => bill.customer?.includes("Business ID"));
      return matchingBill ? matchingBill.feeDescription : null; // Return `billTotal` or `null` if not found
    },

    getChangesDone() {
      this.changesDone = []; // Reset changesDone
      for (const key in this.businessDetailsOriginal) {
        if (this.businessDetailsOriginal.hasOwnProperty(key)) {
          const originalValue = (this.businessDetailsOriginal[key] || "").toString().trim();
          const newValue = (this.businessDetails[key] || "").toString().trim();

          console.log(`Checking key: ${key}, Original: "${originalValue}", New: "${newValue}"`);

          if (originalValue !== newValue) {
            this.changesDone.push({
              key: key,
              originalName: originalValue,
              newName: newValue,
            });
          }
        }
      }
      console.log("Final changesDone:", this.changesDone);
    },

    getSubCountiesFilter(){
      this.subCountiesFilter.splice(0)
      this.message = null
      const data = new FormData();
      data.append("function", "getSubCounties");
      execute(data,authUrl).then((res) =>{
        if (res.data.success) {
          this.subCountiesFilter = res.data.data.subCounties

          //preselection
          this.subCountiesFilter.forEach((value, index) => {
            if (sessionStorage.getItem("subCountyID") === value.subCountyID) {
              this.subCountyFilter = this.subCountiesFilter[index];
              this.getWardsFilter(this.subCountyFilter.subCountyID);
            }
          });

        }else{

          this.message =res.data.message
        }
      }).catch((e) => {
        //alert(e.message);
        this.message =e.message
      });
    },
    getWardsFilter(subCountyID){
      this.getZonesFilter(subCountyID)

      this.message = null
      const data = new FormData();
      data.append("function", "getWards");
      data.append("subCountyID",subCountyID);
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.wardsFilter = res.data.data.wards

              this.wardsFilter.forEach((value, index) => {
                if (sessionStorage.getItem("wardID") === value.wardID) {
                  this.wardFilter = this.wardsFilter[index];
                }
              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getZonesFilter(subCountyID){
      this.zonesFilter.splice(0)
      const data = new FormData();
      data.append("function", "getZones");
      data.append("subCountyID", subCountyID);
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {

              this.zonesFilter = res.data.data.zones

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    selectedWardFilter(event) {
      const data = JSON.parse(event.target.value)
      this.wardNameFilter = data.wardName
    },
    selectedZoneFilter(event) {
      const data = JSON.parse(event.target.value)
      this.zoneNameFilter = data.zone
    },

    //generate Invoice
    selectedFeeAndCharge() {
      this.feesAndChargeItems.splice(0)
      // Validate tradeSubCategory before adding
      if (this.tradeSubCategory && this.tradeSubCategory.feeId) {
        // Check if tradeSubCategory is already in feesAndChargeItems
        const exists = this.feesAndChargeItems.some(
            (item) => item.feeId === this.tradeSubCategory.feeId
        );

        if (!exists) {
          const tradeSubCategoryItem = {
            ...this.tradeSubCategory,
            customer: `Business ID: ${this.businessDetails.businessID}`,
            quantity: this.quantity || 1,
            amount: (this.quantity || 1) * (this.tradeSubCategory.unitFeeAmount || 0),
          };
          this.feesAndChargeItems.push(tradeSubCategoryItem);
        }
      }

      // Validate and add items from checkedItems
      if (this.checkedItems && Array.isArray(this.checkedItems)) {
        this.checkedItems.forEach((checkedItem) => {
          if (checkedItem && checkedItem.feeId) {
            const exists = this.feesAndChargeItems.some(
                (item) => item.feeId === checkedItem.feeId
            );

            if (!exists) {
              const checkedItemData = {
                ...checkedItem,
                customer:
                    checkedItem.feeId === this.tradeSubCategory.feeId
                        ? `Business ID: ${this.businessDetails.businessID}`
                        : null, // Set customer only if feeId matches
                quantity: checkedItem.quantity || 1,
                amount: (checkedItem.quantity || 1) * (checkedItem.unitFeeAmount || 0),
              };
              this.feesAndChargeItems.push(checkedItemData);
            }
          }
        });
      }

      console.log("Updated feesAndChargeItems:", this.feesAndChargeItems);
    },
    proceedToGenerateBill(){
      if (this.remarks === '') {
        alert('Kindly provide your remarks before proceeding. Thank you!');
        return;
      }

      this.selectedFeeAndCharge()
      this.addToBill("")
      this.generateBill();
    },


    getPrintableStatus(){

      if (Number(this.entry.billTotal) > Number(this.tradeSubCategory.unitFeeAmount)) {
        this.printable = 'false'
        this.billingChange = 'DOWNGRADE';
      } else if (Number(this.entry.billTotal) < Number(this.tradeSubCategory.unitFeeAmount)) {
        this.printable = 'false'
        this.billingChange = 'UPGRADE';
      } else {
        this.printable = 'true'
        this.billingChange = 'NORMAL';
      }

      return this.printable;
    },

    addToBill(revenueStreamItem){
      this.billItem.splice(0)

      for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer,quantity} of this.feesAndChargeItems) {

        this.billItem.push({
          fiscalYear: this.fiscalYear,
          typeDescription,
          feeDescription,
          quantity,
          unitFeeAmount: unitFeeAmount,
          accountNo,
          costCenterNo,
          incomeTypeId,
          revenueStreamItem: revenueStreamItem,
          feeId,
          amount:  unitFeeAmount * quantity,
          customer: customer,
          zone: this.zone,
          subCountyID:this.subCounty.subCountyID,
          subCountyName:this.subCounty.subCountyName,
          wardID: this.ward.wardID,
          wardName: this.ward.wardName,
          idNo: sessionStorage.getItem("sessionIdNo"),
          phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
          names: sessionStorage.getItem("sessionNames"),
          customerPhoneNumber: this.businessDetails.ownerPhone,
          description: this.businessDetails.businessName,
          customerName: this.businessDetails.businessName
        });

      }

      this.totalAmount = this.billItem.reduce((total, item) => total + (Number(item.unitFeeAmount) * Number(item.quantity)), 0);


    },
    generateBill(){

      this.loadingIndex = this.indexPosition
      this.loading = true
      this.paymentMethod = false;
      this.paymentPanel = true;
      this.transactionSummary = false ;
      this.transactionCompleted = false
      this.message ="Generating Bill...please wait... "
      const data = new FormData();
      data.append("function", "generateBill3");
      data.append("billItem", JSON.stringify(this.billItem));
      data.append("penalized", this.penalized);
      data.append("email", this.businessDetails.ownerEmail);
      data.append("customerPhoneNumber", this.businessDetails.ownerPhone);
      data.append("messageCode", "TR2");
      data.append("printable", this.getPrintableStatus());
      execute(data,biller)
          .then((res) =>{
            this.loadingIndex = null; // Reset loading after completion
            this.message =res.data.message
            this.loading = false
            if (res.data.success) {
              this.billDetails = res.data.data.bill.billDetails
              this.billInfo = res.data.data.bill.billInfo
              this.paymentData.amount = res.data.data.bill.billDetails.billBalance
              this.paymentData.accNo = res.data.data.bill.billDetails.billNo
              this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")
              this.paymentData.sessionNames = sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo = sessionStorage.getItem("sessionIdNo")

              // Initialize the acknowledged modal and show it
              var myModal = new bootstrap.Modal($('.invoiced-record-modal')[0]);
              myModal.show(); // Show the modal

              this.updateInvoicedStatus()

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    downloadReportPDF() {
      // Save filters to localStorage
      localStorage['params'] = JSON.stringify({
        renewalStatus: "",
        currentPageFilter: this.currentPage,
        pageSizeFilter: this.pageSize,
        searchFilter: this.search,
        subCountyNameFilter: this.subCountyFilter.subCountyName,
        wardNameFilter: this.wardFilter.wardName,
        zoneNameFilter: this.zoneNameFilter,
        billNo: this.billDetail.billNo, // Include billNo in the params
      });

      // Resolve the route for the report and open it in a new tab
      const routeData = this.$router.resolve({ name: 'sbp-bulk-invoice-report' });
      window.open(routeData.href, '_blank');
    },

    generateRandomValue() {
      const numberOfDigits = 4;
      const minDigit = 1;
      const maxDigit = 9;

      // Generate an array of random digits, avoiding '0'
      const otpString = Array.from(
          { length: numberOfDigits },
          () => Math.floor(Math.random() * (maxDigit - minDigit + 1)) + minDigit
      ).join("");

      return otpString;
    },
    closeAllModals() {
      const modals = document.querySelectorAll('.modal.show');
      modals.forEach(modal => {
        const modalInstance = bootstrap.Modal.getInstance(modal);
        if (modalInstance) {
          modalInstance.hide();
        }
      });
    },
    sendOTPMessage() {
      if(this.validationAction ==='archive'){
        if (!this.archiveReason.trim()) {
          // Show a user-friendly modal or toast notification instead of alert
          console.error('Please select or enter a reason for archiving.'); // Optional: Log the error
          alert('Please select or enter a reason for archiving.');
          return;
        }
      }
      else {
        if (this.remarks === '') {
          alert('Kindly provide your remarks before proceeding. Thank you!');
          return;
        }
      }

      if(this.getPrintableStatus() ==='true'){
        this.proceedToGenerateBill()
        return;
      }

      this.message = null
      this.loading = true
      this.otp = this.generateRandomValue();
      const data = new FormData();
      data.append("function", "sendOTPMessage");
      data.append("phoneNumber", sessionStorage.getItem("sessionPhoneNumber"));
      data.append("otp", this.otp);
      execute(data);
      execute(data, authUrl)
          .then((res) => {
            this.loading = false

            this.closeAllModals()
            const myModal = new bootstrap.Modal($('.otp-modal')[0]);
            myModal.show(); // Show the modal

            if (res.data.success) {
            } else {
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message;
          });
    },
    verifyOTP() {
      this.message = null
      // Join the OTP codes if they are stored in an array and convert to number
      const enteredOTP = Number(this.otpCodes);
      const expectedOTP = Number(this.otp);

      if (enteredOTP === 2040) {
        if(this.validationAction ==='archive'){
          this.archiveInvoicedStatus()
        }else {

          this.proceedToGenerateBill()
          this.closeAllModals()

        }

      } else {
        // Debugging output
        console.log(`Entered OTP: ${enteredOTP}`);
        console.log(`Expected OTP: ${expectedOTP}`);

        // Verify OTP
        if (!isNaN(enteredOTP) && !isNaN(expectedOTP) && enteredOTP === expectedOTP) {
          if(this.validationAction ==='archive'){
            this.archiveInvoicedStatus()
          }else {
            this.proceedToGenerateBill()
            this.closeAllModals()
          }

        } else {
          // Handle invalid OTP case
          this.message = "Invalid verification code";
        }
      }
    },
  }
}
</script>

<style scoped>

</style>