<template>

    <head>

        <meta charset="utf-8"/>
        <title>Desk Birdy | Parking Quick Stats</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description"/>
        <meta content="Kelvin Njuguna" name="author"/>
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css"/>

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css"/>
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css"/>

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css"/>
        <!-- Bootstrap select styling -->
        <link rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
        <!-- App Css-->

        <!-- // Add the new slick-theme.css if you want the default styling -->
        <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
        <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

        <!-- owl.carousel css -->
        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css"/>
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css"/>

    </head>

    <body @contextmenu.prevent data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row ">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Parking & Parking Related Fees Quick Stats</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Parking</a></li>
                                        <li class="breadcrumb-item"><a href="#">Quick stats</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                    <ToolBar/>


                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card bg-primary-blue bg-primary-grad">
                                        <div class="card-header d-flex justify-content-between">
                                            <div>
                                                <h4 class="card-title text-white">Seasonal parking Summary </h4>
                                                <p class="text-white d-none">10th Sep 2023 to 17th Sep 2023</p>
                                            </div>
                                            <div>
                                                <div class="float-end">
                                                    <div class="input-group input-group-sm fw-semibold text-uppercase">
                                                        <label class="input-group-text text-uppercase">Subcounties</label>

                                                        <select class="form-select form-select-sm" v-model="subCountyID"
                                                                @change="getSeasonalSummary(subCountyID)">

                                                            <option selected="" value="">All Sub Counties</option>
                                                            <option :key="index" :value="item.subCountyID"
                                                                    v-for="(item, index) in subCounties">
                                                                {{ item.subCountyName }}
                                                            </option>

                                                        </select>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="border-1 border-white">
                                                        <table class="table text-white table-sm fs-6 opacity-7 vertical-align-middle table-borderless performer-table">


                                                            <tbody class="text-uppercase">
                                                            <tr class="">
                                                                <th>
                                                                    <span class="mdi mdi-menu-up fs-3"></span>
                                                                </th>

                                                                <td>Registered Saccos/Sub Groups</td>

                                                                <th class="text-right fw-semibold">
                                                                    {{numberFormat(seasonalSummary.saccosCount)}}
                                                                </th>

                                                            </tr>

                                                            <tr class="">
                                                                <th>
                                                                    <span class="mdi mdi-menu-up fs-3"></span>
                                                                </th>

                                                                <td>Registered Vehicles</td>

                                                                <th class="text-right fw-semibold">
                                                                    {{numberFormat(seasonalSummary.vehiclesCount)}}
                                                                </th>

                                                            </tr>

                                                            <tr class="">
                                                                <th>
                                                                    <span class="mdi mdi-menu-up fs-3"></span>
                                                                </th>

                                                                <td>Compliant Vehicles</td>

                                                                <th class="text-right fw-semibold">
                                                                    {{numberFormat(seasonalSummary.compliantVehiclesCount)}}
                                                                </th>

                                                            </tr>
                                                            <tr class="">
                                                                <th>
                                                                    <span class="mdi mdi-menu-up fs-3"></span>
                                                                </th>

                                                                <td>Uncompliant Vehicles</td>

                                                                <th class="text-right fw-semibold">
                                                                    {{numberFormat(seasonalSummary.unCompliantVehiclesCount)}}
                                                                </th>

                                                            </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="card">
                                        <div class="card-header bg-white border-bottom">
                                            <h4 class="card-title">Parking And Related Revenues</h4>
                                            <p class="text-dark">Summary on revenue collections for Parking and parking
                                                related revenue streams for the period {{formatDate(dateFrom)}} to
                                                {{formatDate(dateTo)}}</p>
                                        </div>
                                        <div class="card-header bg-white border-bottom ">
                                            <div class="d-flex">
                                                <div class="input-group input-group-sm fw-semibold text-uppercase me-3">
                                                    <label class="input-group-text text-uppercase">Date From</label>
                                                    <input v-model="dateFrom" class="form-control form-control-sm"
                                                           type="date" id="example-date-input">
                                                </div>

                                                <div class="input-group input-group-sm fw-semibold text-uppercase">
                                                    <label class="input-group-text text-uppercase">Date To</label>
                                                    <input v-model="dateTo" class="form-control form-control-sm"
                                                           type="date" id="example-date-input">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-responsive font-12">
                                                <table class="table table-striped table-sm font-12 sources-table">
                                                    <thead class="bg-primary text-white text-uppercase">
                                                    <tr class="font-weight-bold text-black">
                                                        <th scope="col">Source</th>

                                                        <th scope="col">Counts</th>
                                                        <th scope="col" class="text-right">Amount (KES)</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr :key="index" :value="item"
                                                        v-for="(item, index) in parkingSummary">
                                                        <td scope="row">{{item.source}}</td>

                                                        <td>{{numberFormat(item.count)}}</td>
                                                        <td class="text-right">{{numberFormat(item.amount)}}</td>
                                                    </tr>

                                                    </tbody>
                                                    <tfoot class="table-success">
                                                    <tr style="background: #cee9d8;">
                                                        <th><strong>Total</strong></th>

                                                        <th>{{numberFormat(totalParkingSummary)}}</th>
                                                        <th class="text-right">{{numberFormat(sumParkingSummary)}}</th>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="card">
                                        <div class="card-header bg-white border-bottom">
                                            <h4 class="card-title">Collections By Vehicle Categories</h4>
                                            <small class="mb-3">For the Period <span class="fw-semibold">{{formatDate(dateFrom2)}} to {{formatDate(dateTo2)}}</span></small>
                                            <div class="flex-start d-flex">
                                                <div class="input-group input-group-sm">
                                                    <select class="form-select form-select-sm" v-model="keyword">
                                                        <option value="DAY" selected="">Daily Parking</option>
                                                        <option value="WEEK">Weekly Parking</option>
                                                        <option value="MONTH">Monthly Parking</option>
                                                        <option value="SEASONAL">Seasonal Parking</option>
                                                        <option value="PARK">Matatu/Bus Park Revenues</option>
                                                        <option value="CLAMP">Clamping Fees</option>
                                                    </select>
                                                    <label class="input-group-text">Revenue Source</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-header bg-white border-bottom ">
                                            <div class="d-flex">

                                                <div class="input-group input-group-sm fw-semibold text-uppercase me-3">
                                                    <label class="input-group-text text-uppercase">Date From</label>
                                                    <input v-model="dateFrom2" class="form-control form-control-sm"
                                                           type="date" id="example-date-input">
                                                </div>

                                                <div class="input-group input-group-sm fw-semibold text-uppercase">
                                                    <label class="input-group-text text-uppercase">Date To</label>
                                                    <input v-model="dateTo2" class="form-control form-control-sm"
                                                           type="date" id="example-date-input">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-responsive font-12">
                                                <table class="table table-striped table-sm font-12 sources-table">
                                                    <thead class="bg-warning text-white text-uppercase">
                                                    <tr class="font-weight-bold text-black">
                                                        <th scope="col">Source</th>

                                                        <th scope="col">Counts</th>
                                                        <th scope="col" class="text-right">Amount (KES)</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    <tr :key="index" :value="item"
                                                        v-for="(item, index) in parkingSummaryCategories">
                                                        <td scope="row">{{item.source}}</td>

                                                        <td>{{numberFormat(item.count)}}</td>
                                                        <td class="text-right">{{numberFormat(item.amount)}}</td>
                                                    </tr>

                                                    </tbody>
                                                    <tfoot class="table-success">
                                                    <tr style="background: #cee9d8;">
                                                        <th><strong>Total</strong></th>

                                                        <th>{{numberFormat(totalParkingCategories)}}</th>
                                                        <th class="text-right">{{numberFormat(sumParkingCategories)}}
                                                        </th>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-12 col-md-8">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card bg-primary-blue bg-primary-grad">
                                        <div class="card-header d-flex justify-content-between">
                                            <div>
                                                <h4 class="card-title text-white text-uppercase">Daily Parking
                                                    Chart </h4>
                                                <p class="text-white d-none">10th Sep 2023 to 17th Sep 2023</p>
                                            </div>
                                            <div>
                                                <div class="float-end">

                                                    <div class="input-group input-group-sm fw-semibold text-uppercase">
                                                        <label class="input-group-text text-uppercase">Date</label>
                                                        <input v-model="date" class="form-control form-control-sm"
                                                               type="date" id="example-date-input">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="card mini-stats-wid blue-card-in">
                                                        <div class="card-body">
                                                            <div class="d-flex">
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted fw-medium text-white">Total
                                                                        Parking Revenue</p>
                                                                    <h4 class="mb-0 text-white fw-bold text-light ">KES
                                                                        {{numberFormat(chatParking.parkingRevenue.current.amount)}}
                                                                        <small class="fw-light">{{numberFormat(getDifference(chatParking.parkingRevenue.current.amount,chatParking.parkingRevenue.previous.amount))}}</small>
                                                                    </h4>
                                                                    <div class="d-flex mt-3">
                                                                        <span class="ms-2 text-truncate text-warning">{{numberFormat(chatParking.parkingRevenue.current.count)}} Transactions</span>
                                                                    </div>
                                                                </div>

                                                                <div class="flex-shrink-0 align-self-center">
                                                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-success">
                                                                        <span class="avatar-title">
                                                                            <i class="mdi mdi-car-brake-parking font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="card mini-stats-wid blue-card-in">
                                                        <div class="card-body">
                                                            <div class="d-flex">
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted fw-medium text-white">Clamping
                                                                        Revenue</p>
                                                                    <h4 class="mb-0 text-white fw-bold text-light ">KES
                                                                        {{numberFormat(chatParking.clampingRevenue.current.amount)}}
                                                                        <small class="fw-light">{{numberFormat(getDifference(chatParking.clampingRevenue.current.amount,chatParking.clampingRevenue.previous.amount))}}</small>
                                                                    </h4>
                                                                    <div class="d-flex mt-3">
                                                                        <span class="ms-2 text-truncate text-warning">{{numberFormat(chatParking.clampingRevenue.current.count)}} Transactions</span>
                                                                    </div>
                                                                </div>

                                                                <div class="flex-shrink-0 align-self-center">
                                                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                                        <span class="avatar-title">
                                                                            <i class="mdi mdi-lock-check font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="card mini-stats-wid blue-card-in">
                                                        <div class="card-body">
                                                            <div class="d-flex">
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted fw-medium text-white">Currently
                                                                        Clamped vehicles</p>
                                                                    <h4 class="mb-0 text-white fw-bold text-light ">
                                                                        {{numberFormat(chatParking.clampedVehicles.current.count)}} </h4>
                                                                    <div class="d-flex mt-3">
                                                                        <span class="ms-2 text-truncate text-warning">KES {{numberFormat(chatParking.clampedVehicles.current.amount)}} Expected Revenue</span>
                                                                    </div>
                                                                </div>

                                                                <div class="flex-shrink-0 align-self-center">
                                                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-warning">
                                                                        <span class="avatar-title">
                                                                            <i class="mdi mdi-lock-alert font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title text-white text-uppercase">Today's Hourly Parking
                                                Revenue trend</h4>
                                        </div>
                                        <div class="card-body " id="weekly-rev">

                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header d-flex justify-content-between bg-white border-bottom">
                                            <div>
                                                <h4 class="card-title text-uppercase">Parking Revenue Collections By Sub Counties</h4>
                                                <p class="">{{formatDate(dateFrom3)}} to {{formatDate(dateTo3)}}</p>
                                            </div>
                                            <div class="">
                                                <div class="float-end d-flex">
                                                    <div class="input-group input-group-sm fw-semibold text-uppercase">
                                                        <label class="input-group-text text-uppercase">From</label>
                                                        <input v-model="dateFrom3" class="form-control form-control-sm" type="date" id="example-date-input">
                                                    </div>

                                                    <div class="input-group input-group-sm fw-semibold text-uppercase">
                                                        <label class="input-group-text text-uppercase">To:</label>
                                                        <input v-model="dateTo3" class="form-control form-control-sm" type="date" id="example-date-input">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">

                                            <div id="subCounty-collections"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end of toolbar -->
                    <div class="row">


                        <div class="col-sm-12  px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white">
                                    <h4 class="card-title">Annual Parking Revenue Perfomance For this financial
                                        year</h4>
                                </div>

                                <div class="card-body">

                                    <div id="revenuePerformanceGraph"></div>


                                </div>

                            </div>
                        </div>

                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
                            <span
                                    class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">Active
                                Certificate</span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                                             id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Kelvin Omondi</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 12:59 AM</div>
                                                        <h5 class="mb-4">Details Verification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Kelvin Ouma</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">20 Sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Premices Inspection</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Alex Kinoti</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Approval Of Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:25 AM</div>
                                                        <h5 class="mb-4">issueance Of Certifcate</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">By 31 Dec 2023 12:00 AM</div>
                                                        <h5 class="mb-4">Renewal</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bx-down-arrow-circle h1 text-info down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">-</a></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-success border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-certification font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Certificate details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial
                                                                No.</p>
                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Last Paid for</p>
                                                        </td>
                                                        <td class="">{{formattedDate}}</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expiry
                                                                Date</p>

                                                        </td>
                                                        <td class="">31 Dec 2023</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days
                                                                to expiry</p>

                                                        </td>
                                                        <td class="">204 Days</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Printing status</p>

                                                        </td>
                                                        <td class="">Printed</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount
                                                                Paid</p>

                                                        </td>
                                                        <td class="text-uppercase text-black fw-semibold">KES 25,000
                                                        </td>

                                                    </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Name</p>
                                                        </td>
                                                        <td class="">Wireless Electronics</td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Certificate of Incoporation No. </p>
                                                        </td>
                                                        <td class="">1234557768</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                Pin
                                                                No.</p>

                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                No.
                                                            </p>

                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Activity</p>

                                                        </td>
                                                        <td class="">Accomodation and Catering</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Sub category</p>

                                                        </td>
                                                        <td class="">Small Lodging House</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Full Names</p>
                                                        </td>
                                                        <td class="">Mr Alex Wanjala Akinyi</td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                ID/PPT No.</p>
                                                        </td>
                                                        <td class="">1234557768</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Fax</p>

                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                Address</p>

                                                        </td>
                                                        <td class="">12-1004 Nairobi</td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Mobile No.</p>
                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Phone No</p>
                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>


                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Email address</p>
                                                        </td>
                                                        <td class=""><a
                                                                href="mailto:email@email.com">email@email.com</a>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                county</p>

                                                        </td>
                                                        <td class="">Homabay Town Sub county</td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                            </p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Physical address/Street</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">Along Moi Avenue
                                                            opposite cooperative Bank</a></td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Building Name</p>

                                                        </td>
                                                        <td class="">ALexis complex</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Stall/Room No.</p>

                                                        </td>
                                                        <td class="">122</td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div>
                                                <iframe
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske"
                                                        width="100%" height="450" style="border:0;" allowfullscreen=""
                                                        loading="lazy"
                                                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                               class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                        <tr>
                                            <th style="width: 70px;">No.</th>
                                            <th>Item</th>
                                            <th class="text-end">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Rent Deposit</td>
                                            <td class="text-end">KES 24,500</td>
                                        </tr>

                                        <tr>
                                            <td>02</td>
                                            <td>Monthly Rent</td>
                                            <td class="text-end">KES 12,000</td>
                                        </tr>

                                        <tr>
                                            <td>03</td>
                                            <td>TAX (18%)</td>
                                            <td class="text-end">KES 250</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-end">Total</td>
                                            <td class="text-end fw-bold">KES 36,750</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-end">Paid</td>
                                            <td class="text-end  fw-bold">KES 0.00</td>
                                        </tr>

                                        <tr>
                                            <td colspan="2" class="border-0 text-end">
                                                <strong>Balance</strong>
                                            </td>
                                            <td class="border-0 text-end">
                                                <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                        <tr data-id="1">
                                            <td>
                                                <label for="" class="">Payment Method</label>
                                                <select class="form-control selectpicker w-100 payment-method"
                                                        data-style="btn-secondary w-100" data-live-search="true"
                                                        title="Select payment Method">
                                                    <option value="Mpesa">MPESA</option>
                                                    <option value="Cash">CASH</option>
                                                </select>
                                            </td>
                                            <td class="px-3 ">
                                                <div class="phone-num d-none">
                                                    <label for="">Phone No.</label>
                                                    <input type="text " class="form-control w-100 d-flex "
                                                           placeholder="Phone No." spellcheck="false"
                                                           data-ms-editor="true">
                                                </div>

                                            </td>
                                            <td class="px-3">
                                                <label for="">Amount To Be Paid</label>
                                                <input type="text " class="form-control w-100 d-flex"
                                                       placeholder="KES" spellcheck="false" data-ms-editor="true">

                                            </td>
                                            <td class="text-right float-right">
                                                <div class="d-flex flex-column">
                                                    <label class="opacity-0">Something</label>
                                                    <a href="#"
                                                       class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                </div>
                                            </td>


                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()"
                                   class="btn btn-success waves-effect waves-light me-1"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);"
                                   class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>


    </body>


</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/parking/ToolBar";
    import {parking, execute, biller} from "@/api";

    export default {
        name: "ParkingDashboard",
        components: {ToolBar, NavigationBar},
        data() {
            return {
                date: '',
                keyword: '',
                dateFrom: '',
                dateTo: '',
                dateFrom2: '',
                dateTo2: '',
                dateFrom3: '',
                dateTo3: '',
                sumParkingSummary: 0,
                totalParkingSummary: 0,
                totalParkingCategories: 0,
                sumParkingCategories: 0,
                formattedDate: '',
                subCountyID: '',
                seasonalSummary: {
                    saccosCount: 0,
                    vehiclesCount: 0,
                    compliantVehiclesCount: 0,
                    unCompliantVehiclesCount: 0
                },
                subCounties: [{
                    subCountyID: "",
                    subCountyName: ""
                }],
                parkingSummary: [{
                    source: "",
                    count: "2",
                    amount: "7800"
                }
                ],
                parkingSummaryCategories: [
                    {
                        source: "",
                        count: "54",
                        amount: "4000"
                    }],
                chatParking: {
                    parkingRevenue: {
                        current: {
                            count: "0",
                            amount: "0"
                        },
                        previous: {
                            count: "0",
                            amount: "0"
                        }
                    },
                    clampingRevenue: {
                        current: {
                            count: "0",
                            amount: "0"
                        },
                        previous: {
                            count: "0",
                            amount: "0"
                        }
                    },
                    clampedVehicles: {
                        current: {
                            count: "0",
                            amount: "0"
                        },
                        previous: {
                            count: "0",
                            amount: "0"
                        }
                    }
                },
                hourlyData: [],
                parkingData: [
                    {
                        subCountyID: "120572",
                        name: "BUURI EAST",
                        y: "0",
                        drilldown: "BUURI EAST"
                    }],
                parkingDrilldown: [
                    {
                        name: "BUURI EAST",
                        id: "BUURI EAST",
                        data: [
                            [
                                "BUURI EAST OFFICE",
                                0
                            ]]
                    }],
                parkingRevenuePerformance: [[
                    "Jul 2023",
                        0,
                        0,
                        20907]]
            }
        },

        mounted() {

            this.date = this.getCurrentDate()
            this.dateFrom = this.getCurrentDate()
            this.dateTo = this.getCurrentDate()

            this.dateFrom2 = this.getCurrentDate()
            this.dateTo2 = this.getCurrentDate()

            this.dateFrom3 = this.getCurrentDate()
            this.dateTo3 = this.getCurrentDate()

            this.$watch('dateFrom', function (newValue) {
                this.getParkingSummary()
            })
            this.$watch('dateTo', function (newValue) {
                this.getParkingSummary()
            })

            this.$watch('dateFrom2', function (newValue) {
                this.getParkingSummaryCategories()
            })
            this.$watch('dateTo2', function (newValue) {
                this.getParkingSummaryCategories()
            })

            this.$watch('dateFrom3', function (newValue) {
                this.getParkingBySubCounties()
            })
            this.$watch('dateTo3', function (newValue) {
                this.getParkingBySubCounties()
            })

            this.$watch('keyword', function (newValue) {
                this.getParkingSummaryCategories()
            })
            this.$watch('date', function (newValue) {
                this.getDailyParkingChart()
                this.getHourly()
            })




            this.getSubCounties()
            this.getSeasonalSummary()
            this.getTodaysDate()
            this.getParkingSummary()
            this.getParkingSummaryCategories()
            this.getDailyParkingChart()
            this.getHourly()
            this.getParkingBySubCounties()
            this.getParkingRevenuePerformance()
          this.menuAccess()

        },
        methods: {
          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Parking Dashboard");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then(() => {
                  // alert(res.data.message);
                })
                .catch(() => {
                  // alert(e.message);
                });

          },
            revenuePerformance() {
                // Abbreviate months to three letters
                function abbreviateMonth(month) {
                    return month.substring(0, 3);
                }

                // Prepare the data
                const data2 = [
                    ['Jul 2023', 50000, 60000, 55000], // [Month, Last Year, Target, Actual]
                    ['Aug 2023', 55000, 62000, 58000],
                    ['Sep 2023', 52000, 61000, 56000],
                    ['Oct 2023', 53000, 63000, 57000],
                    ['Nov 2023', 54000, 59000, 56000],
                    ['Dec 2023', 60000, 65000, 62000],
                    ['Jan 2024', 58000, 67000, 59000],
                    ['Feb 2024', 59000, 68000, 60000],
                    ['Mar 2024', 61000, 70000, 62000],
                    ['Apr 2024', 60000, 69000, 61000],
                    ['May 2024', 62000, 72000, 63000],
                    ['Jun 2024', 63000, 71000, 64000]
                ];
                const data = this.parkingRevenuePerformance

                // Set the font family to Montserrat
                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Montserrat, sans-serif'
                        }
                    }
                });

                // Create the bar chart
                Highcharts.chart('revenuePerformanceGraph', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: data.map(item => abbreviateMonth(item[0]))
                    },
                    yAxis: {
                        title: {
                            text: 'Revenue (KES)'
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    tooltip: {
                        shared: true,
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>KES {point.y}</b><br/>'
                    },
                    plotOptions: {
                        bar: {
                            pointWidth: 20, // Adjust the width of each column
                            stacking: 'normal', // Prevent stacking
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Last Year',
                        data: data.map(item => item[1])
                    }, {
                        name: 'Target',
                        data: data.map(item => item[2])
                    }, {
                        name: 'This Year',
                        data: data.map(item => item[3])
                    }]
                });

            },

            hourlyParking() {
                // Convert hours to the format 12AM-1AM
                function formatHour(hour) {
                    if (hour === 0) {
                        return '12AM';
                    } else if (hour < 12) {
                        return hour + 'AM';
                    } else if (hour === 12) {
                        return '12PM';
                    } else {
                        return (hour - 12) + 'PM';
                    }
                }

                // Prepare the data
                const data3 = [
                    ['12AM', 5000, 5500, 5200, 4800, 3200, 2800], // [Daily,Monthly,Weekly,Seasonal,Clamping,Matatu]
                    ['1AM', 5200, 5800, 5400, 5000, 3400, 3000],
                    ['2AM', 5100, 5600, 5300, 4900, 3300, 2900],
                    ['3AM', 5300, 5900, 5500, 5100, 3500, 3100],
                    ['4AM', 5400, 6000, 5600, 5200, 3600, 3200],
                    ['5AM', 5500, 6100, 5700, 5300, 3700, 3300],
                    ['6AM', 5300, 5900, 5500, 5100, 3500, 3100],

                    ['7AM', 5000, 5500, 5200, 4800, 3200, 2800],
                    ['8AM', 5200, 5800, 5400, 5000, 3400, 3000],
                    ['8AM', 5100, 5600, 5300, 4900, 3300, 2900],
                    ['10AM', 5300, 5900, 5500, 5100, 3500, 3100],
                    ['11AM', 5400, 6000, 5600, 5200, 3600, 3200],
                    ['12PM', 5500, 6100, 5700, 5300, 3700, 3300],
                    ['1PM', 5300, 5900, 5500, 5100, 3500, 3100],

                    ['2PM', 5000, 5500, 5800, 400, 200, 100],
                    ['3PM', 5200, 5800, 5400, 5000, 3400, 3000],
                    ['4PM', 5100, 5600, 5300, 4900, 3300, 2900],
                    ['5PM', 5300, 5900, 5500, 5100, 3500, 3100],
                    ['6PM', 5400, 6000, 5600, 5200, 3600, 3200],
                    ['7PM', 5500, 6100, 5700, 5300, 3700, 3300],
                    ['8PM', 5300, 5900, 5500, 5100, 3500, 3100],

                    ['9PM', 5000, 5500, 5200, 4800, 3200, 2800],
                    ['10PM', 5200, 5800, 5400, 5800, 5400, 5000],
                    ['11PM', 5100, 5600, 5300, 4900, 3300, 2900]
                ];
                const data = this.hourlyData

                // Set the font family to Montserrat
                Highcharts.setOptions({
                    chart: {
                        style: {
                            fontFamily: 'Montserrat, sans-serif'
                        },
                        backgroundColor: 'transparent'
                    }
                });

                // Create the weekly spline chart with hours on the x-axis
                Highcharts.chart('weekly-rev', {
                    chart: {
                        type: 'spline'
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: data.map(item => item[0]),
                        labels: {
                            style: {
                                color: 'white'
                            }
                        }
                    },
                    yAxis: {
                        title: {
                            text: 'Revenue (KES)',
                            style: {
                                color: 'white'
                            }
                        },
                        labels: {
                            style: {
                                color: 'white'
                            }
                        },
                        gridLineWidth: 1,
                        gridLineColor: 'rgba(255, 255, 255, 0.5)',
                        gridLineDashStyle: 'dot'
                    },
                    legend: {
                        enabled: true,
                        itemStyle: {
                            color: 'white'
                        }
                    },
                    tooltip: {
                        shared: true,
                        useHTML: true,
                        formatter: function () {
                            let tooltip = '<span style="color:' + this.points[0].color + '">\u25CF</span> ' + this.x + '<br/>';
                            this.points.forEach(point => {
                                tooltip += '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>KES ' + point.y + '</b><br/>';
                            });
                            tooltip += '<span style="color:gray">\u25CF</span> Total: <b>KES ' + this.points.reduce((total, point) => total + point.y, 0) + '</b>';
                            return tooltip;
                        },
                        backgroundColor: '#023382',
                        style: {
                            color: 'white'
                        }
                    },
                    plotOptions: {
                        spline: { // Use spline plot options
                            marker: {
                                enabled: false // Disable markers on the line
                            }
                        }
                    },

                    series: [{
                        name: 'Daily Parking',
                        data: data.map(item => item[1]),
                        color: "#6CB8FF"
                    }, {
                        name: 'Weekly Parking',
                        data: data.map(item => item[2]),
                        color: "#3C9EFF"
                    }, {
                        name: 'Monthly Parking',
                        data: data.map(item => item[3]),
                        color: "#FF4500"
                    },
                        {
                            name: 'Seasonal Parking',
                            data: data.map(item => item[4]),
                            color: "#32CD32"
                        },
                        {
                            name: 'Matatu/Bus Park Fees',
                            data: data.map(item => item[5]),
                            color: "#FFD700"
                        },
                        {
                            name: 'Clamping Fees',
                            data: data.map(item => item[6]),
                            color: "white"
                        }
                    ]
                });
            },

                 parkingRevenueCollectionsBySubCounties() {
                Highcharts.chart('subCounty-collections', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        align: 'left',
                        text: null
                    },

                    accessibility: {
                        announceNewData: {
                            enabled: true
                        }
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        title: {
                            text: 'Collected Revenue'
                        }

                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            pointWidth: 40, // Set the desired column width
                            borderRadius: 0, // Remove border radius
                            dataLabels: {
                                enabled: true,
                                inside: true,
                                format: 'KES {point.y:,.0f}',  // Updated format to include 'KES' and remove decimals
                                rotation: -90,
                                align: 'left',
                                verticalAlign: 'middle',
                                x: 4,
                                y: 10,
                                style: {
                                    fontSize: '12px'
                                }
                            },
                            color: 'black', // Set column color to black
                            color: '#023382'
                        },

                    },

                    tooltip: {
                        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>KES {point.y:,.0f}</b><br/>'
                    },


                    series: [
                        {
                            name: 'Sub County Collections',
                            colorByPoint: false,
                            data: this.parkingData
                        }
                    ],
                    drilldown: {
                        breadcrumbs: {
                            position: {
                                align: 'left'
                            }
                        },
                        series: this.parkingDrilldown
                    }
                });

                function generateRandomNumber() {
                    return Math.floor(Math.random() * (500000 - 40000 + 1)) + 40000;
                }


            },

            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getDifference(value1, value2) {
                // Convert values to integers or numbers before finding the difference
                const intValue1 = parseInt(value1, 10);
                const intValue2 = parseInt(value2, 10);

                // Check if conversion is successful and then find the difference
                if (!isNaN(intValue1) && !isNaN(intValue2)) {
                    return intValue1 - intValue2;
                } else {
                    // Handle the case where conversion fails
                    console.error('Invalid input. Please provide valid numeric values.');
                    return null; // or handle it as you see fit
                }
            },
            getCurrentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const day = String(today.getDate()).padStart(2, "0");

                return `${year}-${month}-${day}`;
            },

            getSubCounties() {
                const data = new FormData();
                data.append("function", "getSubCounty");
                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getHourly() {
                this.hourlyData.splice(0)
                const data = new FormData();
                data.append("function", "getHourly");
                data.append("date", this.date);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {
                            this.hourlyData = res.data.data.hourlyData
                            this.hourlyParking()
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },

            getParkingBySubCounties() {
                this.parkingData.splice(0)
                this.parkingDrilldown.splice(0)
                const data = new FormData();
                data.append("function", "getParkingBySubCounties");
                data.append("dateFrom", this.dateFrom2);
                data.append("dateTo", this.dateTo2);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {
                            this.parkingData = res.data.data.parkingData
                            this.parkingDrilldown = res.data.data.parkingDrilldown
                            this.parkingRevenueCollectionsBySubCounties()
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getParkingRevenuePerformance() {
                this.parkingRevenuePerformance.splice(0)
                const data = new FormData();
                data.append("function", "getParkingRevenuePerformance");
                data.append("dateFrom", this.dateFrom2);
                data.append("dateTo", this.dateTo2);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {
                            this.parkingRevenuePerformance = res.data.data.parkingRevenuePerformance
                            this.revenuePerformance()
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getParkingSummaryCategories() {

                this.totalParkingCategories = 0
                this.sumParkingCategories = 0

                this.parkingSummaryCategories.splice(0)

                const data = new FormData();
                data.append("function", "getParkingSummaryCategories");
                data.append("keyword", this.keyword);
                data.append("dateFrom", this.dateFrom2);
                data.append("dateTo", this.dateTo2);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {

                            this.parkingSummaryCategories = res.data.data.parkingSummaryCategories

                            this.totalParkingCategories = this.parkingSummaryCategories.reduce((acc, cur) => {
                                let value = Number(cur.count);
                                if (!isNaN(value)) {
                                    return acc + value;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.sumParkingCategories = this.parkingSummaryCategories.reduce((acc, cur) => {
                                let value = Number(cur.amount);
                                if (!isNaN(value)) {
                                    return acc + value;
                                } else {
                                    return acc;
                                }
                            }, 0);
                        } else {

                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getSeasonalSummary() {
                const data = new FormData();
                data.append("function", "getSeasonalSummary");
                data.append("subCountyID", this.subCountyID);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {
                            this.seasonalSummary = res.data.data.seasonalSummary


                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getDailyParkingChart() {
                const data = new FormData();
                data.append("function", "getDailyParkingChart");
                data.append("date", this.date);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {
                            this.chatParking = res.data.data.chatParking
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', {month: 'short'});
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            getParkingSummary() {
                this.parkingSummary.splice(0)
                const data = new FormData();
                data.append("function", "getParkingSummary");
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                execute(data, parking)
                    .then((res) => {
                        if (res.data.success) {
                            this.parkingSummary = res.data.data.parkingSummary

                            this.totalParkingSummary = this.parkingSummary.reduce((acc, cur) => {
                                let value = Number(cur.count);
                                if (!isNaN(value)) {
                                    return acc + value;
                                } else {
                                    return acc;
                                }
                            }, 0);
                            this.sumParkingSummary = this.parkingSummary.reduce((acc, cur) => {
                                let value = Number(cur.amount);
                                if (!isNaN(value)) {
                                    return acc + value;
                                } else {
                                    return acc;
                                }
                            }, 0);
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getTodaysDate() {
                const today = new Date();
                const options = {year: 'numeric', month: 'short', day: 'numeric'};
                this.formattedDate = today.toLocaleDateString('en-GB', options);
            }
        }
    }
</script>

<style scoped>

</style>